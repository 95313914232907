import React, { useEffect, useState } from "react";
import {
  useReferenceRegisterMutation,
  useReferenceUpdateMutation,
} from "../../services/userAuthAPI";
import { getToken } from "../../services/localStorageServices";
import { Button, Form, Input, Modal, message } from "antd";
import TextArea from "antd/es/input/TextArea";

const ReferalRefrenceModal = ({
  show,
  hideModal,
  type,
  dataList,
  setDataList,
  formType,
  formData,
}) => {
  const [referenceRegister] = useReferenceRegisterMutation();
  const [referenceUpdate] = useReferenceUpdateMutation();
  const { access_token } = getToken();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    setLoading(true);
    const referalData = {
      doctor_references_name: values.name,
      doctor_refrences_specialization: values.speciallization,
      doctor_refernces_mobile: values.mobile,
      doctor_refernces_email: values.email,
      doctor_referncesl_address: values.address,
      doctor_references_type: type,
      token: access_token,
    };

    // do something with the form data, e.g. send it to the server
    const req = await referenceRegister(referalData);
    if (req.data) {
      const listDataRef = dataList;

      const referalData = {
        doctor_references_id: req.data.id,
        doctor_references_name: values.name,
        doctor_refrences_specialization: values.speciallization,
        doctor_refernces_mobile: values.mobile,
        doctor_refernces_email: values.email,
        doctor_referncesl_address: values.address,
        doctor_references_type: type,
      };

      listDataRef.push(referalData);

      setDataList([...listDataRef]);
      setLoading(false);
      hideModal();
      message.success("Added Successfull");
      form.resetFields();
    } else {
      message.error("Unable to add");
    }
  };

  useEffect(() => {
    if (formType === "update") {
      form.setFieldsValue({
        id: formData.doctor_references_id,
        name: formData.doctor_references_name,
        speciallization: formData.doctor_refrences_specialization,
        mobile: formData.doctor_refernces_mobile,
        email: formData.doctor_refernces_email,
        address: formData.doctor_referncesl_address,
        type: formData.doctor_references_type,
      });
    }else{
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [formData, formType, form]);

  const handleUpdate = async (values) => {
    setLoading(true);
    const referalData = {
      doctor_references_id: values.id,
      doctor_references_name: values.name,
      doctor_refrences_specialization: values.speciallization,
      doctor_refernces_mobile: values.mobile,
      doctor_refernces_email: values.email,
      doctor_referncesl_address: values.address,
      doctor_references_type: type,
      token: access_token,
    };

    // do something with the form data, e.g. send it to the server
    const req = await referenceUpdate(referalData);
    if (req.data) {
      const listDataRef = dataList;
      const index = listDataRef.findIndex(
        (value) => value.doctor_references_id === values.id
      );

      listDataRef[index] = {
        ...listDataRef[index], // Keep existing properties
        doctor_references_name: values.name,
        doctor_refrences_specialization: values.speciallization,
        doctor_refernces_mobile: values.mobile,
        doctor_refernces_email: values.email,
        doctor_referncesl_address: values.address,
        doctor_references_type: type,
      };

      setDataList([...listDataRef]);

      setLoading(false);
      hideModal();

      message.success("Updated Successfull");
      form.resetFields();
    } else {
      message.error("Unable to update");
    }
  };

  return (
    <Modal
      open={show}
      centered
      onCancel={hideModal}
      footer={[]}
      title={(formType === "add" ? "Add " : "Update ") + type}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={formType === "add" ? handleSubmit : handleUpdate}
      >
        <div className="row">
          <div className="col-12 d-none">
            <Form.Item label="Enter Name" name="id">
              <Input type="text" placeholder="Name" />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item
              label="Enter Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="text" placeholder="Name" />
            </Form.Item>
          </div>
          <Form.Item
            name="speciallization"
            label="Enter Speciallization"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="text" placeholder="Speciallization" />
          </Form.Item>
          <div className="col-md-6">
            <Form.Item
              label="Mobile No."
              name="mobile"
              rules={[
                {
                  required: true,
                },
                { max: 10 },
                {
                  min: 10,
                },
              ]}
            >
              <Input type="number" placeholder="Enter mobile no." />
            </Form.Item>
          </div>
          <div className="col-md-6">
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                },
                {
                  type: "email",
                },
              ]}
            >
              <Input type="email" placeholder="Enter Email ID" />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea placeholder="Enter Address" row="6" showCount />
            </Form.Item>
          </div>
          <div className="d-flex justify-content-end align-items-center w-100">
            <Button className="Cancelbtn" onClick={hideModal}>
              Cancel
            </Button>
            <Button className="Clickbtn" htmlType="submit" loading={loading}>
              {formType === "add" ? "Submit" : "Update"}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
export default ReferalRefrenceModal;
