import { Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { TbReport } from "react-icons/tb";

export default function SecondDesing({
  onAppointmentFormOpening,
  bookedList,
  formattedDate,
  slotList,
  hospitalId,
  timeSlots,
  VitalFunction,
}) {
  return slotList.map((obj, index) => (
    <div key={index} className="row mb-3">
      <div className="col-md-2 col-12 mb-md-0 mb-2">
        <div className="TimeDisplay">
          <h6>{obj}</h6>
        </div>
      </div>
      <div className="col-md-1 d-md-flex d-none align-items-center">
        <strong>-</strong>
      </div>
      <div className="col-md-9 col-12" style={{ position: "relative" }}>
        <div className="Shedular-List-Data-main-con ">
          <div
            className={`Shedular-List-Data-con ${
              moment().isAfter(
                moment(formattedDate + " " + obj, "YYYY-MM-DD h:mm A")
              )
                ? bookedList.some(
                    (value) => value.appointment_start_time === obj
                  )
                  ? bookedList.filter(
                      (valueData) => valueData.appointment_start_time === obj
                    )[0].appointment_status === "block"
                    ? "Done-List-Card"
                    : bookedList.filter(
                      (valueData) => valueData.appointment_start_time === obj
                    )[0].appointment_status === "visit" ? "Success-list-card" :"Booked-List-Card"
                  : "Done-List-Card"
                : bookedList.some(
                    (value) => value.appointment_start_time === obj
                  )
                ? bookedList.filter(
                  (valueData) => valueData.appointment_start_time === obj
                )[0].appointment_status === "block"
                ? "Done-List-Card" : bookedList.filter(
                    (valueData) => valueData.appointment_start_time === obj
                  )[0].appointment_status === "visit"
                  ? "Success-list-card"
                  : "Booked-List-Card"
                : "Available-List-Card"
            }`}
            onDoubleClick={() =>
              onAppointmentFormOpening(
                moment().isAfter(
                  moment(formattedDate + " " + obj, "YYYY-MM-DD h:mm A")
                )
                  ? bookedList.some(
                      (value) => value.appointment_start_time === obj
                    )
                    ? "navigateData"
                    : true
                  : bookedList.some(
                      (value) => value.appointment_start_time === obj
                    )
                  ? "navigateData"
                  : false,
                obj,
                hospitalId,
                formattedDate,
                obj,
                "online"
              )
            }
          >
            {bookedList.map((value, i) =>
              value.appointment_start_time === obj &&
              value.appointment_status !== "block" ? (
                <>
                  <div key={i} className="Shedular-List-Data-profile-con-Main">
                    <div className="Shedular-List-Data-profile-con">
                      <div className="Shedular-List-Data-profile-Name">
                        <h5>
                          <span>
                            {value.patient_first_name}
                            &nbsp;
                            {value.patient_last_name}
                          </span>
                          &nbsp;
                          <span>
                            (
                            {moment().diff(
                              value.pateint_date_of_birth,
                              "Years"
                            )}
                            &nbsp;Y&nbsp;)
                          </span>
                        </h5>
                        <h5>+91 {value.patient_phone_no}</h5>
                        <h6 className="d-sm-none d-block ">
                          {value.purpose_of_visit !== ""
                            ? `(` + value.purpose_of_visit + `)`
                            : ""}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <div className="Shedular-List-Data-Age-con-Main">
                    <div className="Shedular-List-Data-Age-con">
                      <div className="Shedular-List-Data-Age-Name">
                        <h6 className="d-sm-block d-none">
                          {value.purpose_of_visit !== "" &&
                          value.purpose_of_visit !== null
                            ? `(` + value.purpose_of_visit + `)`
                            : ""}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="Shedular-Box-card-Date">
                    {bookedList.map((value, index) =>
                      value.appointment_start_time === obj &&
                      value.appointment_status !== "block" ? (
                        <div
                          key={index}
                          className="afterBookedDetail d-flex justify-content-end align-items-center"
                        >
                          {/* <Popover
                        content={
                          <div>
                            <p style={{color:"darkorange"}}>Balance to pay</p>
                            <p className="d-flex justify-content-between align-items-center"><div className="paymentDetail d-flex justify-content-between align-items-center"><PiCurrencyInrBold /> 500</div>  <button className="btn Clickbtn">Paid</button></p>
                           
                          </div>
                        }
                        title="Fees Details"
                        trigger="click"
                      >
                        <Tooltip title="500 Remainaing">
                          <button className="btn Cancelbtn">
                            <FaWallet />
                          </button>
                        </Tooltip>
                      </Popover> */}
                          <Tooltip title="Add Vital">
                            <button
                              className="btn Clickbtn"
                              onClick={() =>
                                VitalFunction(value.patient_id_reff)
                              }
                            >
                              <TbReport />
                            </button>
                          </Tooltip>
                        </div>
                      ) : null
                    )}
                  </div>
                </>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  ));
}
