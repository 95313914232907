import { DatePicker, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

export default function Compaign({ filtData }) {
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [contact, setContact] = useState(null);

  useEffect(() => {
    const updateDate = (name, start, contact) => {
      filtData.setData({ name: name, date: moment(new Date(start)).format("DD MMM hh:mm A"), contact: contact });
    };

    updateDate(name, startDate, contact);
    // eslint-disable-next-line
  }, [contact, startDate, name]);

  return (
    <>
      <Input
        placeholder="Enter Compaign Name"
        className="formInput"
        onChange={(e) => setName(e.target.value)}
      />
      <DatePicker
        showTime
        format="DD MMM hh:mm A"
        use12Hours
        className="formInput w-100 my-3"
        onChange={(value) => setStartDate(value)}
      />
      <Input
        placeholder="Website / Phone / Email"
        className="formInput"
        onChange={(e) => setContact(e.target.value)}
      />
    </>
  );
}
