import React, { useState, useEffect } from "react";
import SideBar from "../components/Sidebar/SideBar";
import { useNavigate, useParams } from "react-router-dom";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import "../css/Appointments.css";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { getToken } from "../services/localStorageServices";
import moment from "moment";
import AppointmentModel from "../components/AddAppointmentBox/AddAppointmentbox";
import TablePagination from "@mui/material/TablePagination";
import { Skeleton } from "@mui/material";
import VitalModal from "../components/HistoryComponents/vitalAddModal";
import { SiWindows11 } from "react-icons/si";
import { BsArrowUp, BsListUl } from "react-icons/bs";
import { BiRefresh } from "react-icons/bi";
import { Switch, Tooltip } from "antd";
import noSlot from "../Images/noSlot.webp";
import OfflineAppointment from "../components/AppointmentLists/OfflineAppointments/OfflineAppointment.jsx";
import OnlineAppointments from "../components/AppointmentLists/OnlineAppointments/OnlineAppointments.jsx";
import { IoInfinite } from "react-icons/io5";
import { AiOutlineGlobal } from "react-icons/ai";
import { FaUserDoctor } from "react-icons/fa6";
// import { FaInfoCircle } from "react-icons/fa";
import PatientInfo from "../components/PatientInfo/PatientInfo.jsx";

// import SwipeUpDetector from './SwipeUpDetector';

const Appointments = () => {
  const { access_token } = getToken();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(new Date());
  const [bookedList, setBookedList] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [hospitalId, setHospitalId] = useState();
  const [bookedAppointment, setBookedAppointment] = useState(false);
  const [failedAppointment, setFailedAppointment] = useState(false);
  const [formattedDate, setFormattedDate] = useState();
  const [totalBooked, setTotalBooked] = useState(0);
  const [checkLoginType, setCheckLoginType] = useState(null);

  const [appointmentDetail, setAppointmentDetail] = useState({
    patientName: "",
    patientID: "",
    patientNumber: "",
    purposeVisit: "",
    hospitalId: "",
    gender: "",
    bloodGroup: "",
    dateOfBirth: "",
    age: "",
    address: "",
    pincode: "",
    city: "",
    patientEmail: "",
    dateOfBirthMonth: "",
  });

  const [activeTab, setActiveTab] = useState(0);
  const [slotPage, setSlotPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [totalLength, setTotalLength] = useState(0);
  const [slotList, setSlotList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hopitalView, setHopitalView] = useState([]);
  const [loader, setLoader] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [refreshCondition, setRefreshCondition] = useState(false);
  const { page } = useParams();

  // ===================== set Online Offline Data ==================
  const [isOnline, setIsOnline] = useState(true);

  const handleSetOnline = () => {
    setSlotPage(0);

    // const message =
    //   "Are sure you want to switch " +
    //   (!isOnline ? "Online" : "Offline") +
    //   " appointments";
    // if (window.confirm(message)) {
    setIsOnline((prev) => (prev === true ? false : true));

    sessionStorage.setItem(
      "appointment_details",
      JSON.stringify({
        date: value,
        hospId: hospitalId,
        appointmentType: !isOnline,
      })
    );

    bookSlot(
      moment(sessionStorage.getItem("appointment_details").date).format(
        "YYYY-MM-DD"
      ),
      hospitalId,
      1,
      rowsPerPage,
      !isOnline,
      slotList
    );
    // }
  };

  const handleTabClick = (index) => {
    setActiveTab((prev) => (prev === 0 ? 1 : 0));
    localStorage.setItem("activeTab", index);
    // setBackgroundColor("whitesmoke");
  };

  // ======================== Hide Alert ===============================

  useEffect(() => {
    // Set a timer to execute setBookedAppointment(false)n after 10 seconds
    const timerId = setTimeout(() => {
      setBookedAppointment(false);
      setFailedAppointment(false);
    }, 10000);

    // Clean up the timer when the component unmounts or the bookedAppointment state changes
    return () => {
      clearTimeout(timerId);
    };
  }, [bookedAppointment, failedAppointment]);

  useEffect(() => {
    const appointmentDetails = JSON.parse(
      sessionStorage.getItem("appointment_details")
    );
    if (appointmentDetails === null || appointmentDetails.length === 0) {
      let date = new Date(value); // value from your state
      let selectDate = moment(date).format("YYYY-MM-DD");
      setFormattedDate(selectDate);
    } else {
      setHospitalId(appointmentDetails.hospId);
      setValue(appointmentDetails.date);
      setFormattedDate(moment(appointmentDetails.date).format("YYYY-MM-DD"));
      setIsOnline(appointmentDetails.appointmentType);
    }

    // =================== Check Active Tab ======================

    const activeTabCheck = parseInt(localStorage.getItem("activeTab"));
    if (isNaN(activeTabCheck)) {
      setActiveTab(0);
    } else {
      setActiveTab(activeTabCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);

    let date = new Date(newValue);

    let formattedDateSelect = moment(date).format("YYYY-MM-DD");
    setFormattedDate(formattedDateSelect);

    sessionStorage.setItem(
      "appointment_details",
      JSON.stringify({
        date: date,
        hospId: hospitalId,
        appointmentType: isOnline,
      })
    );
    sheduleFetch(hospitalId, formattedDateSelect);
    setSlotPage(0);
  };

  // ================================== Hospital List fetch function =============================

  const hospitalView = () => {
    fetch("https://skdm.in/skddev/DrCrm/Account_List_View.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setHopitalView(apiData);
        sessionStorage.setItem("hospaitl_list", JSON.stringify(apiData));
        const hospitalIdData = apiData[0].list_of_account;
        setHospitalId(hospitalIdData);

        sessionStorage.setItem(
          "appointment_details",
          JSON.stringify({
            date: value,
            hospId: hospitalIdData,
            appointmentType: isOnline,
          })
        );
        // sheduleFetch(hospitalIdData, formattedDate);
      });
  };

  const fetchFunctionLocal = (apiData, hospitalIdData) => {
    setHopitalView(apiData);
    sessionStorage.setItem(
      "appointment_details",
      JSON.stringify({
        date: value,
        hospId: hospitalIdData,
        appointmentType: isOnline,
      })
    );
    sheduleFetch(hospitalIdData, formattedDate);
  };

  // ==================== checking Data from Local is set or not =================
  useEffect(() => {
    let bounceTime;
    bounceTime = setTimeout(() => {
      const hospital_list = JSON.parse(sessionStorage.getItem("hospaitl_list"));

      if (hospital_list === null || hospital_list.length === 0) {
        hospitalView();
      } else {
        if (
          hospitalId !== "" &&
          hospitalId !== undefined &&
          hospitalId !== null
        ) {
          fetchFunctionLocal(hospital_list, hospitalId);
        } else {
          const hospitalIdData = hospital_list[0].list_of_account;
          setHospitalId(hospitalIdData);
          fetchFunctionLocal(hospital_list, hospitalIdData);
        }
      }
    }, 100);

    return () => clearTimeout(bounceTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hospitalId]);

  // =========================== Hospital Shedule Fetch ======================

  const sheduleFetch = (id, seledteDdate) => {
    const day = moment(seledteDdate).format("dddd");

    let hospitalShedule = [];
    const hospitalDetailList = JSON.parse(
      sessionStorage.getItem("hospaitl_list")
    );
    if (hospitalDetailList && hospitalDetailList.length > 0) {
      hospitalShedule = hospitalDetailList
        .filter((valueArray) => valueArray.list_of_account === id)
        .flatMap((filteredArray) => filteredArray.hospital_shedule);
    } else {
      hospitalShedule = hopitalView
        .filter((valueArray) => valueArray.list_of_account === id)
        .flatMap((filteredArray) => filteredArray.hospital_shedule);
    }
    if (hospitalShedule.length > 0) {
      const selectedData = hospitalShedule.filter(
        (obj) => obj.hospital_weeek_name === day
      );

      if (selectedData.length > 0) {
        const timeSlotsData = createTimeSlot(
          selectedData[0].hospital_start_time,
          selectedData[0].hospital_end_time,
          selectedData[0].hospital_slot_duration
        );
        setTimeSlots(timeSlotsData);
        const newData = getPageData(slotPage, rowsPerPage, timeSlotsData);
        setSlotList(newData);
        bookSlot(seledteDdate, id, slotPage, rowsPerPage, isOnline, newData);
      } else {
        setSlotList([]);
        setTimeSlots([]);
      }
      setLoading(false);
    } else {
      setSlotList([]);
      setTimeSlots([]);
    }
  };

  // ============================== Pagination Function =========================
  const handleChangePage = async (event, newPage) => {
    setSlotPage(newPage);
    const newData = getPageData(newPage, rowsPerPage, timeSlots);
    setSlotList(newData);
    bookSlot(
      formattedDate,
      hospitalId,
      newPage,
      rowsPerPage,
      isOnline,
      newData
    );
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setSlotPage(0);
    const newData = getPageData(0, newRowsPerPage, timeSlots);
    setSlotList(newData);
    bookSlot(formattedDate, hospitalId, 0, newRowsPerPage, isOnline, newData);
  };

  function getPageData(pageData, rowSize, timeSlotsData) {
    const pageSize = rowSize;

    setTotalLength(Math.ceil(timeSlotsData.length));

    var pageNo;
    if (pageData <= 0) {
      pageNo = 1;
    } else {
      pageNo = pageData + 1;
    }

    // Calculate the start and end indexes for the page
    const startIndex = (pageNo - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Return the data for the page
    return timeSlotsData.slice(startIndex, endIndex);
  }

  useEffect(() => {
    let clearTimeData;
    clearTimeData = setTimeout(() => {
      const newData = getPageData(slotPage, rowsPerPage, timeSlots);
      setSlotList(newData);
    }, 500);

    return () => clearTimeout(clearTimeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSlots]);

  // ------------- Slot Generate Funtion --------------------

  const createTimeSlot = (fromTime, toTime, duration) => {
    let startTime = moment(fromTime, "hh:mm A");
    let endTime = moment(toTime, "hh:mm A");

    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }
    let arr = [];
    while (startTime <= endTime) {
      arr.push(new moment(startTime).format("hh:mm A"));
      startTime.add(duration, "minutes");
    }
    return arr;
  };

  // ============== Appointments Fetch ==================

  const bookSlot = (
    currentDate,
    hospital_id,
    page,
    limit,
    checkOnline,
    slotListData
  ) => {
    const checkAppointmentData = checkAppointmentList(
      slotListData,
      currentDate
    );

    if (
      checkAppointmentData &&
      checkAppointmentData.length > 0 &&
      navigator.onLine
    ) {
      const requestBody = {
        token: access_token,
        appointment_date: currentDate,
        hospital_ID: hospital_id,
        type: checkOnline ? "online" : "offline",
      };

      if (checkOnline) {
        requestBody.slotRange = checkAppointmentData;
      } else {
        requestBody.page = page;
        requestBody.limit = limit;
      }

      setLoading(true);
      setLoader(true);
      fetch("https://skdm.in/skddev/DrCrm/booking_appointment_view.php", {
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((resp) => resp.json())
        .then((apiData) => {
          setLoader(false);
          setLoading(false);
          setTotalBooked(apiData.total);
          const getItem = sessionStorage.getItem("appointment_list");

          if (getItem && checkOnline) {
            const getItemArray = JSON.parse(getItem);
            const allMergeData = [...getItemArray, ...apiData.list];
            const typeAppointments = checkOnline ? "online" : "offline";
            setBookedList(
              allMergeData.filter((value) => {
                return (
                  value.appointment_date === currentDate &&
                  value.account_list_id_reff === hospital_id &&
                  value.appointment_type === typeAppointments
                );
              })
            );

            sessionStorage.setItem(
              "appointment_list",
              JSON.stringify(allMergeData)
            );
          } else {
            setBookedList(apiData.list);
            sessionStorage.setItem(
              "appointment_list",
              JSON.stringify(apiData.list)
            );
          }
        });
    } else {
      const getItem = JSON.parse(sessionStorage.getItem("appointment_list"));
      const typeAppointments = checkOnline ? "online" : "offline";

      setBookedList(
        getItem.filter((value) => {
          return (
            value.appointment_date === currentDate &&
            value.account_list_id_reff === hospital_id &&
            value.appointment_type === typeAppointments
          );
        })
      );
    }
  };

  // ==================== Open Appointment Detail Modal ===============
  const [activeDialogue, setActiveDialogue] = useState(false);
  const [timeSlot, setTimeSlot] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const navigate = useNavigate();

  function onAppointmentFormOpening(
    disabledData,
    time,
    hospitalIdSelcted,
    formattedDateClick,
    timeSlot,
    appointmentType
  ) {
    if (disabledData === false) {
      setActiveDialogue(true);
      setTimeSlot(time);
      setSelectedDate(formattedDate);
      setAppointmentDetail({
        patientName: "",
        patientID: "",
        patientNumber: "",
        purposeVisit: "",
        hospitalId: hospitalId,
        gender: "",
        bloodGroup: "",
        dateOfBirth: "",
        age: "",
        address: "",
        pincode: "",
        city: "",
        patientEmail: "",
        dateOfBirthMonth: "",
        appointmentType: appointmentType,
      });
    }
    if (disabledData === "navigateData") {
      if (checkLoginType && checkLoginType === "reception") {
        alert("Appointment already booked");
      } else {
        bookedList.forEach((value) => {
          if (value.appointment_start_time === timeSlot) {
            const patient_Id = value.patient_id_reff;
            navigate(
              "/appointmentSteps/" +
                patient_Id +
                "/" +
                hospitalIdSelcted +
                "/" +
                formattedDateClick +
                "/" +
                timeSlot +
                "/" +
                appointmentType
            );
          }
        });
      }
    }
  }

  // ===================== Changing hospital ======================
  const hospitalAppointment = (e) => {
    sessionStorage.setItem(
      "appointment_details",
      JSON.stringify({
        date: value,
        hospId: e.target.value,
        appointmentType: isOnline,
      })
    );

    // sheduleFetch(e.target.value, formattedDate);
    // bookSlot(formattedDate, e.target.value);
    setSlotPage(0);
    setHospitalId(e.target.value);
  };

  const skeletonArray = [];
  const skeletonListArray = [];

  for (let i = 0; i < 8; i++) {
    skeletonArray.push(
      <div className="col-lg-3 col-md-4 col-sm-6 col-12 p-2">
        <Skeleton variant="rounded" width="100%" height={150} />
      </div>
    );
  }

  for (let i = 0; i < 8; i++) {
    skeletonListArray.push(
      <div className="col-12 mb-2">
        <Skeleton variant="rounded" width="100%" height={80} />
      </div>
    );
  }

  const [pid, setPid] = useState();

  const VitalFunction = (patientId) => {
    setPid(patientId);
    setShow(true);
  };

  // =============================================== Refresh Appointment =====================

  const refershAppointment = () => {
    const appointmentDetails = JSON.parse(
      sessionStorage.getItem("appointment_details")
    );
    if (appointmentDetails === null || appointmentDetails.length === 0) {
      console.log("not found");
    } else {
      bookSlot(
        moment(appointmentDetails.date).format("YYYY-MM-DD"),
        appointmentDetails.hospId,
        slotPage,
        rowsPerPage,
        isOnline,
        slotList
      );
    }
  };

  useEffect(() => {
    // Check if refreshCondition is true
    if (refreshCondition) {
      // Call the function immediately
      // refershAppointment();

      // Set up an interval to periodically check refreshCondition
      const intervalId = setInterval(() => {
        // If refreshCondition is true, call the function and clear the interval
        if (refreshCondition) {
          refershAppointment();
          clearInterval(intervalId);
        }
      }, 15000);

      // Clear the interval if the component unmounts
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, [refreshCondition]);

  // ========================= On Swipe Hide Filter List ====================

  const [startY, setStartY] = useState(null);

  const handleTouchStart = (event) => {
    setStartY(event.touches[0].clientY);
  };

  const handleTouchEnd = (event) => {
    if (startY !== null) {
      const deltaY = startY - event.changedTouches[0].clientY;
      if (deltaY > 100) {
        setFilterShow(false);
      }
      setStartY(null);
    }
  };

  const [smsData, setSmsData] = useState(null);

  useEffect(() => {
    if (page !== "reception") {
      const data = JSON.parse(sessionStorage.getItem("doctorData"));

      if (data && data !== null) {
        const smsData = data.sms_detaiil.find(
          (value) => value.IntegrationType === "sms"
        );
        setSmsData(smsData);
      }
    }

    const checkLoginType = () => {
      if (page === "reception") {
        setCheckLoginType("reception");
      } else {
        const loginType = localStorage.getItem("loginType");
        if (loginType !== null) {
          setCheckLoginType("reception");
        }
      }
    };

    checkLoginType();
  }, [page]);

  // ========================= Fetch Search Data List ===============================

  const FetchSearchDataList = () => {
    try {
      fetch("https://skdm.in/skddev/DrCrm/prescription_detail_list.php", {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((resp) => resp.json())
        .then((apiData) => {
          localStorage.setItem("suggestionList", JSON.stringify(apiData));
        });
    } catch (error) {
      console.log(error);
    }
  };

  // ============ Check Online  ============
  useEffect(() => {
    if (navigator.onLine) {
      const suggestionData = JSON.parse(localStorage.getItem("suggestionList"));

      if (!suggestionData) {
        let dbounseData;
        dbounseData = setTimeout(() => {
          FetchSearchDataList();
        }, 3000);

        return () => clearTimeout(dbounseData);
      }
    }
    // eslint-disable-next-line
  }, []);

  // ========== Check Available Booked ===========
  const availableBook = (totalList, totalBooked) => {
    const availableBooked = parseInt(totalList) - parseInt(totalBooked);

    return availableBooked;
  };
  // ============ Check Booked Appointments =========
  const checkAppointmentList = (slotListData, currentDate) => {
    const storeAppointment = JSON.parse(
      sessionStorage.getItem("appointment_list")
    );
    let notFoundList = slotListData;
    if (storeAppointment && storeAppointment.length > 0) {
      notFoundList = slotListData.filter((valueData) => {
        return !storeAppointment.some(
          (value) =>
            value.appointment_start_time === valueData &&
            value.appointment_date === currentDate
        );
      });
    }
    return notFoundList;
  };

  return (
    <>
      <VitalModal
        show={show}
        setShow={setShow}
        close={() => setShow(false)}
        pid={pid}
        appointment="appointment"
      />

      <SideBar>
        <NavbarComp />
        <div
          className={
            checkLoginType && checkLoginType === "reception"
              ? `receptionHeader`
              : `navbarBodyMargin`
          }
        >
          {smsData && parseInt(smsData.RemainingCredit) <= 0 ? (
            <div className="lessSmsRemaining error">
              your sms balance is - 0 sms service has stoped.
            </div>
          ) : smsData && parseInt(smsData.RemainingCredit) < 100 ? (
            <div className="lessSmsRemaining warning">
              You do not have sufficent sms - {smsData.RemainingCredit} SMS
              remaining.
            </div>
          ) : null}
          {/* ======================= Large Screen UI =================== */}
          <div className="section1 d-xl-block d-none">
            <section className="Scheduler-Page-con">
              <div className="Scheduler-Page-Head-con">
                <div className="Scheduler-Page-Head">
                  <div className="row">
                    <div className="col-xl-3 col-sm-4 mb-3 d-flex align-items-center justify-content-start ">
                      <div className="Scheduler-Page-Head-Datepicker w-100">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              label=""
                              inputFormat="DD/MM/YYYY"
                              value={value}
                              onChange={handleChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6 mb-3 d-flex align-items-center justify-content-center">
                      <div className="Scheduler-Page-Head-Details-con">
                        <div className="Scheduler-Page-Head-Details">
                          <div className="Scheduler-Page-Head-text">
                            <h3>total</h3>&nbsp;
                            <span>
                              {isOnline
                                ? timeSlots.length.toString(10).padStart(2, "0")
                                : totalBooked}
                            </span>
                          </div>
                          <div className="Scheduler-Page-Head-text">
                            <h3>booked</h3>&nbsp;
                            <span>{totalBooked}</span>
                          </div>
                          <div className="Scheduler-Page-Head-text">
                            <h3>available</h3>&nbsp;
                            <span>
                              {isOnline ? (
                                availableBook(timeSlots.length, totalBooked)
                              ) : (
                                <IoInfinite />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-sm-4 mb-3 d-flex align-items-center justify-content-end">
                      <div className="Scheduler-Page-Head-hospital-info">
                        <div className="Scheduler-Page-Head-hospital-drop">
                          <div className="input-style-1 w-100">
                            <select
                              name="Clinic"
                              id="Clinic"
                              className="form-select hospitalSelect"
                              onChange={hospitalAppointment}
                              defaultValue={hospitalId}
                            >
                              {hopitalView && hopitalView.length > 0 ? (
                                hopitalView.map((obj, key) => (
                                  <option
                                    key={obj.list_of_account}
                                    value={obj.list_of_account}
                                  >
                                    {obj.first_name}
                                  </option>
                                ))
                              ) : (
                                <option>No Hospital Found</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-6 mt-3 d-flex align-items-center justify-content-start">
                      <div className="page-handling-con">
                        <div className="paginationList">
                          <TablePagination
                            component="div"
                            count={
                              isOnline ? parseInt(totalLength) : totalBooked
                            }
                            page={slotPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[20, 30, 50, 100]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-12 mt-3 d-flex align-items-center justify-content-center ">
                      <div className="Shedular-Color-Con row">
                        <div
                          className="Shedular-Color col-lg-3 col-6 d-grid"
                          style={{ gridTemplateColumns: "auto auto" }}
                        >
                          <div className="Shedular-color-code BookedColor"></div>
                          <h4>Booked</h4>
                        </div>

                        <div
                          className="Shedular-Color col-lg-3 col-6 d-grid"
                          style={{ gridTemplateColumns: "auto auto" }}
                        >
                          <div className="Shedular-color-code NotBookedColor"></div>
                          <h4>Disabled</h4>
                        </div>
                        <div
                          className="Shedular-Color col-lg-3 col-6 d-grid"
                          style={{ gridTemplateColumns: "auto auto" }}
                        >
                          <div className="Shedular-color-code VisitedColor"></div>
                          <h4>Visited</h4>
                        </div>
                        <div
                          className="Shedular-Color col-lg-3 col-6 d-grid"
                          style={{ gridTemplateColumns: "auto auto" }}
                        >
                          <div className="Shedular-color-code AvailableColor"></div>
                          <h4>Available</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-4 mt-3 d-flex align-items-center justify-content-end ">
                      <div className="page-List-Style-con">
                        <PatientInfo
                          timeSlots={timeSlots}
                          hospitalId={hospitalId}
                          hospitalName={
                            hopitalView?.find(
                              (value) => value.list_of_account === hospitalId
                            )?.first_name
                          }
                        />
                        <div className="page-btn-controll-con">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTabClick(1)}
                          >
                            <SiWindows11 />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTabClick(0)}
                          >
                            <BsListUl />
                          </span>
                          <button
                            id="Box-page-btn-controll"
                            style={{ cursor: "default" }}
                            className={activeTab === 0 ? `grid` : `list`}
                          >
                            {activeTab === 0 ? <SiWindows11 /> : <BsListUl />}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* ==================== Middle screen UI ====================== */}
          <div className="section1 d-xl-none d-sm-block d-none">
            <section className="Scheduler-Page-con">
              <div className="Scheduler-Page-Head-con">
                <div className="Scheduler-Page-Head">
                  <div className="row">
                    <div className="col-6 mb-3 d-flex align-items-center justify-content-start ">
                      <div className="Scheduler-Page-Head-Datepicker">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              label=""
                              inputFormat="DD/MM/YYYY"
                              value={value}
                              onChange={handleChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div className="col-6 mb-3 d-flex align-items-center justify-content-end">
                      <div className="Scheduler-Page-Head-hospital-info">
                        <div className="Scheduler-Page-Head-hospital-drop">
                          <div className="input-style-1 w-100">
                            <select
                              name="Clinic"
                              id="Clinic"
                              className="form-select hospitalSelect"
                              onChange={hospitalAppointment}
                              defaultValue={hospitalId}
                            >
                              {hopitalView && hopitalView.length > 0 ? (
                                hopitalView.map((obj) => (
                                  <option
                                    key={obj.list_of_account}
                                    value={obj.list_of_account}
                                  >
                                    {obj.first_name}
                                  </option>
                                ))
                              ) : (
                                <option>No Hospital Found</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-4 col-12 mt-3">
                      <div className="Shedular-Color-Con row">
                        <div className="Shedular-Color mb-2 col-lg-3 col-md-6 col-sm-3 col-6 d-flex justify-content-sm-start justify-content-center align-items-center">
                          <div className="Shedular-color-code BookedColor"></div>
                          <h4>Booked</h4>
                        </div>

                        <div className="Shedular-Color mb-2 col-lg-3 col-md-6 col-sm-3 col-6 d-flex justify-content-sm-start justify-content-center align-items-center">
                          <div className="Shedular-color-code NotBookedColor"></div>
                          <h4>Disabled</h4>
                        </div>
                        <div className="Shedular-Color mb-2 col-lg-3 col-md-6 col-sm-3 col-6 d-flex justify-content-sm-start justify-content-center align-items-center">
                          <div className="Shedular-color-code VisitedColor"></div>
                          <h4>Visited</h4>
                        </div>
                        <div className="Shedular-Color mb-2 col-lg-3 col-md-6 col-sm-3 col-6 d-flex justify-content-sm-start justify-content-center align-items-center">
                          <div className="Shedular-color-code AvailableColor"></div>
                          <h4>Available</h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-8 col-12 mb-3 d-flex align-items-center justify-content-md-end justify-content-center">
                      <div className="Scheduler-Page-Head-Details-con">
                        <div className="Scheduler-Page-Head-Details">
                          <div className="Scheduler-Page-Head-text">
                            <h3>total</h3>&nbsp;
                            <span>
                              {isOnline
                                ? timeSlots.length.toString(10).padStart(2, "0")
                                : totalBooked}
                            </span>
                          </div>
                          <div className="Scheduler-Page-Head-text">
                            <h3>booked</h3>&nbsp;
                            <span>{totalBooked}</span>
                          </div>
                          <div className="Scheduler-Page-Head-text">
                            <h3>available</h3>&nbsp;
                            <span>
                              {isOnline ? (
                                availableBook(timeSlots.length, totalBooked)
                              ) : (
                                <IoInfinite />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-8 mt-3 d-flex align-items-center justify-content-start">
                      <div className="page-handling-con">
                        <div className="paginationList">
                          <TablePagination
                            component="div"
                            count={
                              isOnline ? parseInt(totalLength) : totalBooked
                            }
                            page={slotPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[20, 30, 50, 100]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-4 mt-3 d-flex align-items-center justify-content-end ">
                      <div className="page-List-Style-con">
                        <PatientInfo
                          timeSlots={timeSlots}
                          hospitalId={hospitalId}
                          hospitalName={
                            hopitalView?.find(
                              (value) => value.list_of_account === hospitalId
                            )?.first_name
                          }
                        />

                        <div className="page-btn-controll-con">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTabClick(0)}
                          >
                            <SiWindows11 />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTabClick(1)}
                          >
                            <BsListUl />
                          </span>
                          <button
                            id="Box-page-btn-controll"
                            style={{ cursor: "default" }}
                            className={activeTab === 0 ? `grid` : `list`}
                          >
                            {activeTab === 0 ? <SiWindows11 /> : <BsListUl />}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* ====================== Phone Screen Design ================ */}
          <div
            className={`section1 d-sm-none d-block phoneScreen ${
              filterShow ? `showPhoneScreen` : null
            }`}
          >
            <section
              className="Scheduler-Page-con"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
            >
              <div className="Scheduler-Page-Head-con">
                <div className="Scheduler-Page-Head">
                  <div className="row">
                    <div className="col-12 mb-3 d-flex align-items-center justify-content-start ">
                      <div className="Scheduler-Page-Head-Datepicker w-100">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              label=""
                              inputFormat="DD/MM/YYYY"
                              value={value}
                              onChange={handleChange}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div className="col-12 mb-3 d-flex align-items-center justify-content-end">
                      <div className="Scheduler-Page-Head-hospital-info w-100">
                        <div className="Scheduler-Page-Head-hospital-drop w-100">
                          <div className="input-style-1 w-100">
                            <select
                              name="Clinic"
                              id="Clinic"
                              className="form-select hospitalSelect w-100"
                              onChange={hospitalAppointment}
                              defaultValue={hospitalId}
                            >
                              {hopitalView && hopitalView.length > 0 ? (
                                hopitalView.map((obj) => (
                                  <option
                                    key={obj.list_of_account}
                                    value={obj.list_of_account}
                                  >
                                    {obj.first_name}
                                  </option>
                                ))
                              ) : (
                                <option>No Hospital Found</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-8 col-12 mb-3 d-flex align-items-center justify-content-md-end justify-content-center">
                      <div className="Scheduler-Page-Head-Details-con">
                        <div className="Scheduler-Page-Head-Details">
                          <div className="Scheduler-Page-Head-text">
                            <h3>total</h3>&nbsp;
                            <span>
                              {isOnline
                                ? timeSlots.length.toString(10).padStart(2, "0")
                                : totalBooked}
                            </span>
                          </div>
                          <div className="Scheduler-Page-Head-text">
                            <h3>booked</h3>&nbsp;
                            <span>{totalBooked}</span>
                          </div>
                          <div className="Scheduler-Page-Head-text">
                            <h3>available</h3>&nbsp;
                            <span>
                              {isOnline ? (
                                availableBook(timeSlots.length, totalBooked)
                              ) : (
                                <IoInfinite />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-4 col-12 mt-3 d-flex align-items-center justify-content-start">
                      <div className="page-handling-con">
                        <div className="paginationList">
                          <TablePagination
                            component="div"
                            count={
                              isOnline ? parseInt(totalLength) : totalBooked
                            }
                            page={slotPage}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[20, 30, 50, 100]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-4 col-7 mt-3">
                      <div className="Shedular-Color-Con row">
                        <div
                          className="Shedular-Color mb-2 col-lg-3 col-md-6 col-sm-3 col-6 d-grid"
                          style={{ gridTemplateColumns: "auto auto" }}
                        >
                          <div className="Shedular-color-code BookedColor"></div>
                          <h4>Booked</h4>
                        </div>

                        <div
                          className="Shedular-Color mb-2 col-lg-3 col-md-6 col-sm-3 col-6 d-grid"
                          style={{ gridTemplateColumns: "auto auto" }}
                        >
                          <div className="Shedular-color-code NotBookedColor"></div>
                          <h4>Disabled</h4>
                        </div>
                        <div
                          className="Shedular-Color mb-2 col-lg-3 col-md-6 col-sm-3 col-6 d-grid"
                          style={{ gridTemplateColumns: "auto auto" }}
                        >
                          <div className="Shedular-color-code VisitedColor"></div>
                          <h4>Visited</h4>
                        </div>
                        <div
                          className="Shedular-Color mb-2 col-lg-3 col-md-6 col-sm-3 col-6 d-grid"
                          style={{ gridTemplateColumns: "auto auto" }}
                        >
                          <div className="Shedular-color-code AvailableColor"></div>
                          <h4>Available</h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-5 mt-3 d-flex align-items-center justify-content-end ">
                      <div className="page-List-Style-con">
                        <div className="page-btn-controll-con">
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTabClick(0)}
                          >
                            <SiWindows11 />
                          </span>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={() => handleTabClick(1)}
                          >
                            <BsListUl />
                          </span>
                          <button
                            id="Box-page-btn-controll"
                            style={{ cursor: "default" }}
                            className={activeTab === 0 ? `grid` : `list`}
                          >
                            {activeTab === 0 ? <SiWindows11 /> : <BsListUl />}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="arrowHint">
                    <div className="arrowIcon">
                      <BsArrowUp />
                    </div>
                    <p>Scroll Up to hide popup</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* =================== Filter Button and refresh button ================ */}
          {slotList && slotList.length > 0 ? (
            <div className="filterList d-flex align-items-center justify-content-between px-3 mt-md-0 mt-4">
              <div className="d-flex justify-content-start align-items-center">
                <div
                  className={loader ? `refershPage refreshing` : `refershPage `}
                  onClick={refershAppointment}
                >
                  <BiRefresh />
                  <h5>{loader ? "Fetching.." : "Refresh"} Appointment</h5>
                </div>
                &nbsp;&nbsp;
                <Tooltip placement="bottom" title="Auto Refresh">
                  <Switch
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    defaultChecked={refreshCondition}
                    onChange={(checked) => setRefreshCondition(checked)}
                  />
                </Tooltip>
              </div>

              <button
                onClick={() =>
                  setFilterShow((prev) => (prev === true ? false : true))
                }
                className="filterAppointment d-sm-none d-block Clickbtn"
              >
                Filter Appt
              </button>

              {/* ================ Filter OffLine And Onlie ============== */}
              <Tooltip
                placement="bottom"
                title={
                  isOnline ? "Online Appointments" : "Offline Appointments"
                }
              >
                <div className="page-List-Style-con">
                  <div className="page-btn-controll-con offLineAndOnline">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSetOnline()}
                    >
                      <AiOutlineGlobal />
                    </span>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleSetOnline()}
                    >
                      <FaUserDoctor />
                    </span>
                    <button
                      style={{ cursor: "default" }}
                      id="Box-page-btn-controll"
                      className={isOnline ? `grid online` : `list offLine`}
                    >
                      {isOnline ? <AiOutlineGlobal /> : <FaUserDoctor />}
                    </button>
                  </div>
                </div>
              </Tooltip>
            </div>
          ) : null}
          {/* ======================= Slot List ========================== */}
          <div className="boxsection1">
            <section className="Shedular-data-con-Main">
              {loading ? (
                <div className="row">{skeletonArray}</div>
              ) : slotList.length > 0 ? (
                isOnline ? (
                  <OnlineAppointments
                    onAppointmentFormOpening={onAppointmentFormOpening}
                    loading={loading}
                    slotList={slotList}
                    skeletonArray={skeletonArray}
                    activeTab={activeTab}
                    bookedList={bookedList}
                    formattedDate={formattedDate}
                    hospitalId={hospitalId}
                    VitalFunction={VitalFunction}
                  />
                ) : (
                  <OfflineAppointment
                    onAppointmentFormOpening={onAppointmentFormOpening}
                    loading={loading}
                    slotList={slotList}
                    timeSlots={timeSlots}
                    skeletonArray={skeletonArray}
                    activeTab={activeTab}
                    hospitalId={hospitalId}
                    bookedList={bookedList}
                    formattedDate={formattedDate}
                    VitalFunction={VitalFunction}
                  />
                )
              ) : (
                <div className="errorMessage d-flex align-items-center flex-column">
                  <img src={noSlot} alt="no slot" width={350} />
                  <h5 style={{ margin: "0", textAlign: "center" }}>
                    No Shedule set for the day
                  </h5>
                </div>
              )}
            </section>
          </div>
        </div>

        {/* ========================= Appointment Modal ==================== */}
        <div>
          <AppointmentModel
            show={activeDialogue}
            close={() => setActiveDialogue(false)}
            slot={timeSlot}
            setAppointmentDetail={setAppointmentDetail}
            appointmentDetail={appointmentDetail}
            hospitalView={hopitalView}
            appointment_date={selectedDate}
            setBookedAppointment={setBookedAppointment}
            setFailedAppointment={setFailedAppointment}
            setBookedList={setBookedList}
            bookedList={bookedList}
            setActiveDialogue={setActiveDialogue}
            timeSlots={timeSlots}
            bookSlot={bookSlot}
            slotList={slotList}
          />
        </div>
      </SideBar>
    </>
  );
};

export default Appointments;
