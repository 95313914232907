import React, { useState } from "react";
// import { Container, Nav, Navbar } from "react-bootstrap";

import "../css/Users.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useDeleteHospitalMutation } from "../services/userAuthAPI";
import { MdDelete, MdEdit } from "react-icons/md";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp.js";
import { getToken } from "../services/localStorageServices";
import { useEffect } from "react";
import AddHospitalSchedule from "../components/AddHospitalSchedule/AddHospitalSchedule.jsx";
import { Skeleton } from "@mui/material";
import { Spin, message } from "antd";

const Hospitals = () => {
  const { access_token } = getToken();
  const [deleteHospital] = useDeleteHospitalMutation();
  const [listData, setListData] = useState([]);
  const [loadingHospital, setLoadingHospital] = useState(true);
  const [loading, setLoading] = useState(false);

  const hospitalList = () => {
    setLoadingHospital(true);
    fetch("https://skdm.in/skddev/DrCrm/Account_List_View.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setLoadingHospital(false);
        setListData(apiData);
        sessionStorage.setItem("hospaitl_list", JSON.stringify(apiData));
      });
  };

  useEffect(() => {
    const hospital_list = JSON.parse(sessionStorage.getItem("hospaitl_list"));

    if (hospital_list === null || hospital_list.length === 0) {
      hospitalList();
    } else {
      setListData(hospital_list);
      setLoadingHospital(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DeleteHospital = async (hospitalIDm, index) => {
    if (window.confirm("Are sure you want remove hospital ?")) {
      setLoading(true);

      const formData = new FormData();
      formData.append("token", access_token);
      formData.append("Hospital_Id", hospitalIDm);
      const req = await deleteHospital(formData);

      if (req.data) {
        listData.splice(index, 1);
        setListData([...listData]);
        sessionStorage.setItem("hospaitl_list", JSON.stringify(listData));
        message.success("Removed Successfully");
        setLoading(false);
      }

      if (req.errror) {
        message.error("Failed to remove");
      }
    }
  };

  // ===== Dropdown ========
  $(".dropdown ul li").on("click", function () {
    var text = $(".default_option").text();
    console.log(text);
    $(".default_option").val(text);
    $(".dropdown ul").removeClass("active");
  });

  // =========================================== Skelaten ======================================

  const skeletonArray = [];

  for (let i = 0; i < 8; i++) {
    skeletonArray.push(
      <div className="col-6 p-2" key={i}>
        <Skeleton variant="rounded" width="100%" height={100} />
      </div>
    );
  }

  return (
    <div>
      <SideBar>
        <NavbarComp />
        <Spin spinning={loading}>
          <div>
            <section className="doctorView navbarBodyMargin">
              <div className="container-fluid">
                <div className="Hospital-Head-Search-Btn-Add-btn">
                  <div className="Hospital-Head-Search-Btn">
                    <h1 className="pageTitle">Hospital List</h1>
                  </div>
                  <div className="Hospital-Head-Add-Btn">
                    <Link to="/addHospital/add">
                      <button
                        className="btn Clickbtn"
                        data-bs-toggle="modal"
                        data-bs-target="#addTaskModal"
                      >
                        <span class="material-symbols-outlined icon">add</span>
                        Add Hospital
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  {loadingHospital ? (
                    skeletonArray
                  ) : listData.length > 0 ? (
                    listData.map((workObj, index) => (
                      <div key={index} className="col-lg-6">
                        <div
                          className="CategoryCard"
                          value={workObj.list_of_account}
                        >
                          <div className="d-flex justify-content-start align-items-start flex-sm-row flex-column">
                            <div
                              className="categImg"
                              style={{ margin: "0", height: "80px" }}
                            >
                              <img
                                src={
                                  workObj.logo === null
                                    ? "https://static.vecteezy.com/system/resources/previews/004/493/181/original/hospital-building-for-healthcare-background-illustration-with-ambulance-car-doctor-patient-nurses-and-medical-clinic-exterior-free-vector.jpg"
                                    : workObj.logo
                                }
                                alt=""
                                style={{ borderRadius: "5px" }}
                              />
                            </div>
                            <div className="categText">
                              <h2>{workObj.first_name}</h2>
                              <p> {workObj.city}</p>
                            </div>
                          </div>
                          <div className="categButton">
                            <div>
                              <Link
                                to={`/addHospital/${workObj.list_of_account}`}
                              >
                                <div
                                  className="iconsStyle edit"
                                  // onClick={() =>
                                  //   EditHospital(workObj.list_of_account)
                                  // }
                                >
                                  <MdEdit />
                                </div>
                              </Link>
                            </div>
                            <div>
                              <div
                                className="iconsStyle delete"
                                onClick={() =>
                                  DeleteHospital(workObj.list_of_account, index)
                                }
                              >
                                <MdDelete />
                              </div>
                            </div>
                            <div>
                              <AddHospitalSchedule
                                hospitalShedule={workObj.hospital_shedule}
                                hospitalID={workObj.list_of_account}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h2>No Hospital Found</h2>
                  )}
                </div>
              </div>
            </section>
          </div>
        </Spin>
      </SideBar>
  
    </div>
  );
};

export default Hospitals;
