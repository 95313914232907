import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "./AddLocationBox.css";
import { DialogTitle } from "@mui/material";
import { FiSearch } from "react-icons/fi";
import { useAddDoctorDataListMutation } from "../../services/userAuthAPI";
import { Radio, Spin } from "antd";
import { FaTrashAlt } from "react-icons/fa";

const AddLocationBox = ({
  show,
  close,
  patientInstruction,
  setPatientInstruction,
  index,
  locationType,
  testLocation,
  setTestLocation,
  token,
  fetchTypeList,
  testLoad,
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [searchList, setSearchList] = useState([]);
  const [addDoctorDataList] = useAddDoctorDataListMutation();
  const [selectedValue, setSelectedValue] = useState();
  const [searchText, setSearchText] = useState("");
  const formSubmit = useRef();

  useEffect(() => {
    let bounceTime = setTimeout(() => {
      if (locationType) {
        fetchTypeList(locationType, searchText);
      }
    }, 500);

    // Cleanup function
    return () => clearTimeout(bounceTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const formName = (e) => {
    e.preventDefault();
    testLocation.forEach((value, i) => {
      if (selectedValue === value.account_text_name) {
        // ============ Add location in list =================
        let newData = [...patientInstruction];
        newData[index].instruction_location_name = value.account_text_name;
        newData[index].instruction_location_mobile = value.account_mobile;
        newData[index].instruction_location_email = value.account_type_name;
        newData[index].instruction_location_address = value.account_address;
        setPatientInstruction(newData);
        setSelectedValue("");
        return;
      }
    });
    close();
  };

  const closeAdd = () => {
    setShowAdd(false);
  };

  const loacationAdd = async (e) => {
    e.preventDefault();
    const data = new FormData(formSubmit.current);

    const datapush = testLocation;
    const searchListFetch = searchList;

    const dataList = {
      account_text_name: data.get("name"),
      account_type_name: data.get("email"),
      account_mobile: data.get("contact"),
      account_address: data.get("address"),
      account_data_type: locationType,
      token: token,
    };
    const min = 1;
    const max = 100;

    const dataListFetch = {
      id: "id_" + Math.floor(min + Math.random() * (max - min)),
      label: data.get("name"),
      email: data.get("email"),
      contact: data.get("contact"),
      address: data.get("address"),
      account_data_type: locationType,
    };

    datapush.push(dataList);
    setTestLocation([...datapush]);

    searchListFetch.push(dataListFetch);
    setSearchList([...searchListFetch]);
    const req = await addDoctorDataList(dataList);
    console.log(req);
    setShowAdd(false);
  };

  return (
    <div>
      <div className="addLocationBox">
        <div>
          <Dialog
            open={show}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "600px", // Set your width here
                },
              },
            }}
          >
            <DialogTitle>
              <div className="popUpFormTitle">
                <span>Location for Test</span>
              </div>
            </DialogTitle>

            <DialogContent>
              <div className="addLocationBoxContent">
                <div className="addNewLocation">
                  <div className="row">
                    <div className="col-sm-9 col-12 order-2 order-sm-1">
                      <div className="newLocationInput searchDiv">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Search here.."
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                        <div className="iconsStyle search">
                          <FiSearch />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-12 order-1 order-sm-2 mb-2 mb-sm-0 d-flex align-items-center">
                      <div className="addNewButton">
                        <button
                          className="Clickbtn"
                          onClick={() => setShowAdd(true)}
                        >
                          Add&nbsp;Location
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="LocationList mt-4">
                    <Spin spinning={testLoad}>
                      <Radio.Group
                        onChange={(e) => setSelectedValue(e.target.value)}
                      >
                        <div className="row">
                          {testLocation &&
                            testLocation.length > 0 &&
                            testLocation.map((value, i) => (
                              <div className="col-lg-4 col-md-6 col-12 d-flex justify-content-between align-items-center">
                                <Radio
                                  style={{ fontSize: ".8rem" }}
                                  value={value.account_text_name}
                                >
                                  {value.account_text_name.toUpperCase()}
                                </Radio>
                                <FaTrashAlt style={{ color: "red" }} />
                              </div>
                            ))}
                        </div>
                      </Radio.Group>
                    </Spin>
                  </div>
                  <div className="buttonGroups margin">
                    <button className="Cancelbtn" onClick={close}>
                      Close
                    </button>
                    <button className="Clickbtn" onClick={formName}>
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            open={showAdd}
            onClose={closeAdd}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "600px", // Set your width here
                },
              },
            }}
          >
            <DialogContent>
              <div className="popUpFormTitle">
                <span>Add Location for Test</span>
              </div>
              <div className="addLocationBoxContent">
                <div className="addNewLocation inputForm">
                  <form ref={formSubmit}>
                    <div className="row">
                      <div className="col-12 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          name="name"
                        />
                      </div>
                      <div className="col-sm-6 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                        />
                      </div>
                      <div className="col-sm-6 mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Contact No."
                          name="contact"
                        />
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          name="address"
                        />
                      </div>
                    </div>
                  </form>

                  <div className="buttonGroups">
                    <button className="Cancelbtn" onClick={closeAdd}>
                      Back
                    </button>
                    <button className="Clickbtn" onClick={loacationAdd}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default AddLocationBox;
