import React, { useState, useEffect } from "react";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import SideBar from "../components/Sidebar/SideBar";
// Icons
import { AiFillEdit } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import "../css/References.css";
import { getToken } from "../services/localStorageServices";
import ReferalRefrenceModal from "../components/referalRefrenceModal/referalRefrenceModal";
import NoDataFound from "./NoDataFound";
import { Pagination, Spin, message } from "antd";

const References = () => {
  const { access_token } = getToken();
  const [referalDataList, setReferalDataList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState(0);
  const [formType, setFormType] = useState();
  const [formData, setFormData] = useState();

  const referenceView = (pageData, limitData) => {
    setLoading(true);
    fetch("https://skdm.in/skddev/DrCrm/doctor_references_view.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
        references_type: "References",
        page: pageData,
        limit: limitData,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())

      .then((apiData) => {
        if (!apiData || apiData === "No Data Found") {
          console.log(apiData);
        } else {
          setLoading(false);
          setReferalDataList(apiData.list);
          setTotal(apiData.total);
        }
      })
      .catch((error) => {
        console.error("Error fetching reference data:", error);
      });
  };

  useEffect(() => {
    const bounseTime = setTimeout(() => {
      referenceView(page, limit);
    }, 500);

    return () => clearTimeout(bounseTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  const DeletePateint = async (reffId, id) => {
    if (window.confirm("Are you sure want to remove list ?")) {
      setLoading(true);

      const req = await fetch(
        "https://skdm.in/skddev/DrCrm/doctor_references_delete.php",
        {
          method: "post",
          body: JSON.stringify({
            doctor_references_id: reffId,
            token: access_token,
          }),
        }
      );

      if (req.ok) {
        referalDataList.splice(id, 1);
        setReferalDataList([...referalDataList]);
        message.success("Reffrence Remove Succesfully");
      } else {
        message.error("Reffrence failed to Remove");
      }
      setLoading(false);
    }
  };

  const referalRefrenceUpdate = (value) => {
    setFormData(value);
    setShowModal(true);
    setFormType("update");
  };

  return (
    <div>
      <ReferalRefrenceModal
        type="References"
        show={showModal}
        hideModal={() => setShowModal(false)}
        dataList={referalDataList}
        setDataList={setReferalDataList}
        formType={formType}
        formData={formData}
      />
      <SideBar>
        <NavbarComp />
        <Spin spinning={loading}>
          <div className="referencesPage navbarBodyMargin">
            {/* Head */}
            <div className="referencesHead mb-5">
              <div className="referenceHeadText">
                <h6 className="pageTitle">Doctor References Listing</h6>
              </div>
              <div className="addReferenceBtn">
                <button
                  onClick={() => {
                    setShowModal(true);
                    setFormType("add");
                  }}
                  className="Clickbtn"
                >
                  + Add Doctor Reference
                </button>
              </div>
            </div>
            {referalDataList && referalDataList.length > 0 ? (
              <>
                {/* ======= References List ========= */}
                <div className="referencesListSection d-lg-block d-none">
                  <table className="table" style={{ textAlign: "center" }}>
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Specialization </th>
                        <th scope="col">Mobile</th>
                        <th scope="col">E-mail</th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {referalDataList.map((workObj, index) => (
                        <tr key={index}>
                          <td>{workObj.doctor_references_name}</td>
                          <td>{workObj.doctor_refrences_specialization}</td>
                          <td>{workObj.doctor_refernces_mobile}</td>
                          <td>{workObj.doctor_refernces_email}</td>

                          <td>
                            <button
                              className="iconsStyle sucess "
                              style={{ color: "#fff !important" }}
                              onClick={() => referalRefrenceUpdate(workObj)}
                            >
                              <AiFillEdit />
                            </button>
                          </td>
                          <td>
                            <button
                              className="iconsStyle delete"
                              style={{ color: "#fff !important" }}
                              onClick={() =>
                                DeletePateint(
                                  workObj.doctor_references_id,
                                  index
                                )
                              }
                            >
                              <MdDelete />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <section className="d-lg-none d-md-block">
                  <div className="container">
                    <div className="row">
                      {referalDataList.map((workObj, index) => (
                        <div key={index} className="col-lg-3 col-md-6 col-12">
                          <div className="customerCard">
                            <ul>
                              <li>
                                Name:
                                <span> {workObj.doctor_references_name}</span>
                              </li>
                              <li>
                                Spec:
                                <span>
                                  {" "}
                                  {workObj.doctor_refrences_specialization}
                                </span>
                              </li>
                              <li>
                                Phone:
                                <span> {workObj.doctor_refernces_mobile}</span>
                              </li>
                              <li>
                                Email:
                                <span> {workObj.doctor_refernces_email}</span>
                              </li>
                            </ul>

                            <div className="smallButton">
                              <button
                                className="iconsStyle sucess "
                                style={{ color: "#fff !important" }}
                                onClick={() =>
                                  referalRefrenceUpdate(
                                    "References",
                                    "update",
                                    workObj,
                                    workObj.doctor_references_id
                                  )
                                }
                              >
                                <AiFillEdit />
                              </button>
                              <button
                                className="iconsStyle delete"
                                style={{ color: "#fff !important" }}
                                onClick={() =>
                                  DeletePateint(
                                    workObj.doctor_references_id,
                                    index
                                  )
                                }
                              >
                                <MdDelete />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
                <Pagination
                  style={{ padding: "10px" }}
                  total={total}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  pageSize={limit}
                  current={page}
                  onChange={(page, pageSize) => {
                    setPage(page);
                    setLimit(pageSize);
                  }}
                />
              </>
            ) : (
              <NoDataFound />
            )}
          </div>
        </Spin>
      </SideBar>
    </div>
  );
};

export default References;
