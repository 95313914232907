import { DatePicker, Radio, Select } from "antd";
import moment from "moment";
import React, { useState } from "react";

const { RangePicker } = DatePicker;

export default function Cancelled({ filtData, hospital }) {
  const [selectedType, setSelectedType] = useState("days");

  const updateDate = (value, type) => {
    let dateData;
    switch (type) {
      case "days":
        dateData = value;
        break;
      case "week":
        dateData = "at " + value;
        break;
      case "singleDate":
        dateData = "on " + moment(new Date(value)).format("DD MMMM");
        break;
      default:
        dateData = value;
        break;
    }

    filtData.setData({ date: dateData, hospitalName: hospital });
  };
  

  return (
    <div>
      <Select
        placeholder="Select Type"
        onChange={(value) => setSelectedType(value)}
        value={selectedType}
        style={{ width: "200px" }}
        options={[
          {
            label: "Days",
            value: "days",
          },
          {
            label: "Week",
            value: "week",
          },
          {
            label: "Single Date",
            value: "singleDate",
          },
        ]}
      />

      {selectedType === "days" ? (
        <Radio.Group
          className="mt-4"
          defaultValue="Today"
          onChange={(e) => updateDate(e.target.value, "days")}
        >
          <Radio value="Today">Today</Radio>
          <Radio value="Tomorrow">Tomorrow</Radio>
          <Radio value="Day after tomorrow">Day after tomorrow</Radio>
        </Radio.Group>
      ) : selectedType === "week" ? (
        <Select
          style={{ width: "150px", margin: "0 10px" }}
          placeholder="Select week name"
          onChange={(value) => updateDate(value, "week")}
          options={[
            {
              label: "Monday",
              value: "Monday",
            },
            {
              label: "Tuseday",
              value: "Tuseday",
            },
            {
              label: "Wednesday",
              value: "Wednesday",
            },
            {
              label: "Thursday",
              value: "Thursday",
            },
            {
              label: "Friday",
              value: "Friday",
            },
            {
              label: "Saturday",
              value: "Saturday",
            },
            {
              label: "Sunday",
              value: "Sunday",
            },
          ]}
        />
      ) : selectedType === "singleDate" ? (
        <DatePicker
          className="formInput mx-3"
          format="DD MMM"
          onChange={(value) => updateDate(value, "singleDate")}
        />
      )  : null}
    </div>
  );
}
