import React from "react";
import { FaList, FaLongArrowAltRight } from "react-icons/fa";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Avatar } from "@mui/material";
import { IoClose } from "react-icons/io5";
import "./nextAppointment.css";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getToken } from "../../services/localStorageServices";

export default function NextAppointment({ setKey }) {
  const [state, setState] = React.useState({
    right: false,
  });
  const [appointmentList, setAppointmentList] = useState([]);
  const { access_token } = getToken();
  const [total, setTotal] = useState();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  // ==================== checking Data from Local is set or not =================
  useEffect(() => {
    const appointment_list_data = JSON.parse(
      sessionStorage.getItem("appointment_list")
    );

    if (appointment_list_data === null || appointment_list_data.length === 0) {
      setAppointmentList([]);
    } else {
      setAppointmentList(appointment_list_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // =============================== Find Next Appointment Id ========================

  const { hid, apdate, aptime,type } = useParams();
  const navigate = useNavigate();
  const nextAppointment = () => {
    const appointmentList = JSON.parse(
      sessionStorage.getItem("appointment_list")
    );

    const currentIndex = appointmentList.findIndex(
      (value) =>
        value.account_list_id_reff === hid &&
        value.appointment_date === apdate &&
        value.appointment_start_time === aptime &&
        value.appointment_type === type
    );

    if (currentIndex !== -1) {
      const nextIndex = currentIndex + 1;
      if (nextIndex < appointmentList.length) {
        const apptHId = appointmentList[nextIndex].account_list_id_reff;
        const apptDate = appointmentList[nextIndex].appointment_date;
        const apptTime = appointmentList[nextIndex].appointment_start_time;
        const apptPId = appointmentList[nextIndex].patient_id_reff;

        navigate(
          `/appointmentSteps/${apptPId}/${apptHId}/${apptDate}/${apptTime}/${type}`
        );
        setKey("DIAGNOSIS");
      } else {
        alert("It's last appointment");
      }
    }
  };

  // ============================ Appointment Find ===================

  const bookSlot = (currentDate, hospital_id) => {
    const ingnoreSlot = checkAppointmentList();

    fetch("https://skdm.in/skddev/DrCrm/booking_appointment_view.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
        appointment_date: currentDate,
        hospital_ID: hospital_id,
        page: 1,
        limit: 10,
        type: type,
        ignoreAppointment: ingnoreSlot,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setTotal(apiData.total);
        const getItem = JSON.parse(sessionStorage.getItem("appointment_list"));

        if (getItem && getItem.length > 0 && type) {
          const allMergeData = [...getItem, ...apiData.list];
          setAppointmentList(
            allMergeData.filter((value) => {
              return (
                value.appointment_date === currentDate &&
                value.account_list_id_reff === hospital_id &&
                value.appointment_type === type
              );
            })
          );

          sessionStorage.setItem(
            "appointment_list",
            JSON.stringify(allMergeData)
          );
        } else {
          setAppointmentList(apiData.list);
          sessionStorage.setItem(
            "appointment_list",
            JSON.stringify(apiData.list)
          );
        }
      });
  };

  const refershAppointment = () => {
    const appointmentDetails = JSON.parse(
      sessionStorage.getItem("appointment_details")
    );
    if (appointmentDetails === null || appointmentDetails.length === 0) {
      console.log("not found");
    } else {
      bookSlot(
        moment(appointmentDetails.date).format("YYYY-MM-DD"),
        appointmentDetails.hospId
      );
    }
  };

  useEffect(() => {
    // Call the delayed function after one minute (60,000 milliseconds)
    const timeoutId = setInterval(refershAppointment, 120000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, []);

  // ============ Check Booked Appointments =========
  const checkAppointmentList = () => {
    const appointmentDetails = JSON.parse(
      sessionStorage.getItem("appointment_details")
    );

    const storeAppointment = JSON.parse(
      sessionStorage.getItem("appointment_list")
    );
    let notFoundList = [];
    if (storeAppointment && storeAppointment.length > 0) {
      notFoundList = storeAppointment
        .filter(
          (value) =>
            value.account_list_id_reff === appointmentDetails.hospId &&
            value.appointment_date ===
              moment(appointmentDetails.date).format("YYYY-MM-DD")
        )
        .map((valueData) => {
          return valueData.appointment_start_time;
        });
    }
    return notFoundList;
  };

  // ================== Appointemnt List Box ====================================
  const list = (anchor) => (
    <Box
      sx={{ width: 350, overflow: "hidden" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="appointmentHead">
        <button onClick={toggleDrawer(anchor, false)}>
          <IoClose />
        </button>
        <div className="appointmentDrawerTitle">
          <h5>Appointment Detail</h5>
          <p>List of another appointments</p>
        </div>

        <div className="appointmentDetainInCount">
          <h6>Total ({appointmentList.length})</h6>
          <h6>
            Attend (
            {
              appointmentList.filter(
                (value) => value.appointment_status === "visit"
              ).length
            }
            )
          </h6>
          <h6>
            Remain (
            {
              appointmentList.filter(
                (value) => value.appointment_status === "pending"
              ).length
            }
            )
          </h6>
        </div>
      </div>
      <div className="appointmentListData">
        {appointmentList && appointmentList.length > 0 ? (
          appointmentList.map((value, index) => (
            <div
              key={index}
              onClick={() => {
                setKey("DIAGNOSIS");
                navigate(
                  `/appointmentSteps/${value.patient_id_reff}/${value.account_list_id_reff}/${value.appointment_date}/${value.appointment_start_time}/${type}`
                );
              }}
              className={`appointmentListCard ${value.appointment_status}`}
            >
              <div className="userDetails">
                <Avatar
                  alt={value.patient_first_name + " " + value.patient_last_name}
                  src={value.patient_profile_Image}
                  sx={{ width: 35, height: 35 }}
                />
                <div className="userInfo">
                  <h5>
                    {value.patient_first_name + " " + value.patient_last_name}
                  </h5>
                  <p>{value.patient_phone_no}</p>
                </div>
              </div>
              <div className="appoinment_details">
                <h5>{value.appointment_start_time}</h5>
                <h5>{value.purpose_of_visit}</h5>
              </div>
            </div>
          ))
        ) : (
          <div className="appointmentListCard">No Appointment Available</div>
        )}
      </div>
    </Box>
  );

  return (
    <div className="d-flex align-items-center justify-content-center flex-column flex-sm-row">
      <button
        className="Clickbtn d-flex align-items-center m-2 m-md-0"
        onClick={nextAppointment}
      >
        Next <FaLongArrowAltRight className="mx-2" />
      </button>
      <button
        className="Clickbtn d-flex align-items-center mx-2"
        onClick={toggleDrawer("right", true)}
      >
        All <FaList className="mx-2" />
      </button>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
}
