import { ConfigProvider, Modal, Tabs } from "antd";
import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import SmsSend from "./SmsSend";
import BlockSlots from "./BlockSlots";

export default function PatientInfo({ hospitalName, hospitalId, timeSlots }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log(hospitalId);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button onClick={showModal} className="Clickbtn mx-3">
        <FaInfoCircle />
        &nbsp;Patient Inform
      </button>
      <Modal
        centered
        title="Inform Patient about your activity"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={900}
        style={{ minHeight: 500 }}
      >
        <ConfigProvider
          theme={{
            components: {
              Tabs: {
                cardBg: "#eee",
                itemSelectedColor: "#000",
              },
              Radio: {
                buttonSolidCheckedBg: "#000",
              },
            },
          }}
        >
          <Tabs
            defaultActiveKey="1"
            type="card"
            items={[
              {
                label: `Send SMS`,
                key: 1,
                children: (
                  <SmsSend
                    hospital={hospitalName}
                    setIsModalOpen={setIsModalOpen}
                    handleCancel={handleCancel}

                  />
                ),
              },
              {
                label: `Block Slots`,
                key: 2,
                children: (
                  <BlockSlots
                    timeSlots={timeSlots}
                    hospitalId={hospitalId}
                    setIsModalOpen={setIsModalOpen}
                    handleCancel={handleCancel}
                  />
                ),
              },
            ]}
          />
        </ConfigProvider>
      </Modal>
    </div>
  );
}
