import React, { useState, useEffect } from "react";
import { getToken, removeToken } from "../../services/localStorageServices";
import LOGO from "../../Images/LOGO.webp";
import { AiOutlineMenu } from "react-icons/ai";
import { useSideBar } from "../../context/sideBar";
import { VscSignOut } from "react-icons/vsc";
import { useNavigate, useParams } from "react-router-dom";
import { Avatar } from "@mui/material";
import InternetAlert from "../InternetCheck/internetAlert";

const NavbarComp = (prop) => {
  const { page } = useParams();
  const { access_token } = getToken();
  const [checkLoginType, setCheckLoginType] = useState(null);

  const [doctorData, setDoctorData] = useState({
    image: "",
    firstname: "",
    lastname: "",
  });

  const fetchDoctorData = () => {
    fetch("https://skdm.in/skddev/DrCrm/Dr_Profile_View.php", {
      method: "POST",
      body: JSON.stringify({
        token: access_token,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((resp) => resp.json())
      .then((apiData) => {
        setDoctorData({
          image: apiData.logo,
          firstname: apiData.first_name,
          lastname: apiData.last_name,
          singnature: apiData.singnature,
          degree: apiData.degree,
        });
        sessionStorage.setItem("doctorData", JSON.stringify(apiData));
      });
  };

  useEffect(() => {
    const doctorDetails = JSON.parse(sessionStorage.getItem("doctorData"));
    if (doctorDetails === null || doctorDetails.length === 0) {
      fetchDoctorData();
    } else {
      const doctorData = JSON.parse(sessionStorage.getItem("doctorData"));
      if (doctorData.status) {
        fetchDoctorData();
      } else {
        setDoctorData({
          image: doctorData.logo,
          firstname: doctorData.first_name,
          lastname: doctorData.last_name,
          singnature: doctorData.singnature,
          degree: doctorData.degree,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token]);

  useEffect(() => {
    if (prop.data) {
      setDoctorData({
        image: prop.data.logo,
        firstname: prop.data.first_name,
        lastname: prop.data.last_name,
        singnature: prop.data.singnature,
        degree: prop.data.degree,
      });
    }
  }, [prop.data]);

  const { toggle } = useSideBar();
  const navigate = useNavigate();

  const logOutFunction = () => {
    removeToken();
    navigate("/", { replace: true });
  };

  useEffect(() => {
    const checkLoginType = () => {
      if (page === "reception") {
        setCheckLoginType("reception");
      } else {
        const loginType = localStorage.getItem("loginType");
        if (loginType !== null) {
          setCheckLoginType("reception");
          navigate("/appointment/reception");
        }
      }
    };
    checkLoginType();
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="main-wrapper">
      {/* <InternetAlert /> */}
      <div className="navbarDiv">
        <div className="container d-flex justify-content-between align-item-center">
          {checkLoginType && checkLoginType === "reception" ? (
            <button
              onClick={logOutFunction}
              style={{
                fontSize: "16px",
                color: "#fff",
                border: "unset",
                background: "transparent",
                padding: "0 7px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <VscSignOut style={{ fontSize: "1.4rem", marginRight: "5px" }} />
              Log Out
            </button>
          ) : null}
          <div className="headerLogo d-none d-sm-block">
            <img style={{ maxWidth: "50px" }} alt="doctar" src={LOGO} />
          </div>
          {page === "reception" ? null : (
            <button
              onClick={toggle}
              className="d-block d-sm-none"
              style={{
                fontSize: "20px",
                color: "#fff",
                border: "unset",
                background: "transparent",
                padding: "0 10px",
              }}
            >
              <AiOutlineMenu sx={{ size: 25 }} />
            </button>
          )}
          <div className="profile-box ml-15">
            <div className="profile-info">
              <div className="info">
                <div className="mx-3" style={{ transform: "scale(1.3)" }}>
                  <Avatar
                    src={doctorData.image}
                    alt={doctorData.firstname + " " + doctorData.lastname}
                  />
                </div>
                <div className="titleData" style={{ textAlign: "left" }}>
                  <h5 style={{ color: "#fff", margin: "unset" }}>
                    Dr. {doctorData.firstname + " " + doctorData.lastname}
                  </h5>
                  <span style={{ color: "#eee" }}>{doctorData.degree}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarComp;
