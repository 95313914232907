import { Input } from "antd";
import React, { useEffect, useState } from "react";

export default function Send({ filtData }) {
  const [name, setName] = useState("");
  const [contact, setContact] = useState(null);

  useEffect(() => {
    const updateDate = (name, contact) => {
      filtData.setData({
        name: name,
        link: contact,
      });
    };

    updateDate(name, contact);
    // eslint-disable-next-line
  }, [contact, name]);

  return (
    <>
      <Input
        placeholder="Enter Compaign Name"
        className="formInput"
        onChange={(e) => setName(e.target.value)}
      />
      <Input
        type="url"
        placeholder="Link"
        className="formInput mt-3"
        onChange={(e) => setContact(e.target.value)}
      />
    </>
  );
}
