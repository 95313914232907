import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./AddDrugBox.css";
import { getToken } from "../../services/localStorageServices";
import { useState } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { RiFileEditFill } from "react-icons/ri";
import medicineShort from "../json/medicineShort.json";
import { useDrugUpdateMutation } from "../../services/userAuthAPI";
import { message } from "antd";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateDrug({
  item,
  drugId,
  setSuggestionList,
  suggestionList,
  index,
}) {
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = React.useState("DrugDetails");
  const { access_token } = getToken();
  const [loader, setLoader] = useState(false);
  const [midicineType, setMidicineType] = useState("");
  const [drugUpdate] = useDrugUpdateMutation();
  const [isSosChecked, setIsSosChecked] = useState(false);
  const [drugDetail, setDrugDetail] = useState(null);
  const [frequencyCheck, setFrequencyChecked] = useState({
    morning: null,
    afternoon: null,
    evening: null,
    night: null,
    sos: null,
  });
  // ====== show oral non-oral ========
  const [oralView, setOralView] = React.useState("oral");

  const toggleOral = () => {
    setOralView((prev) => (prev === "oral" ? "nonOral" : "oral"));
  };

  React.useEffect(() => {
    setOralView(drugDetail?.medicine_category_type);
    setFrequencyChecked({
      morning: drugDetail?.frequency.split(", ")[0],
      afternoon: drugDetail?.frequency.split(", ")[1],
      evening: drugDetail?.frequency.split(", ")[2],
      night: drugDetail?.frequency.split(", ")[3],
      sos: drugDetail?.frequency.split(", ")[4],
    });
    setMidicineType(drugDetail?.medicine_category);
    setIsSosChecked(drugDetail?.frequency.split(", ")[4] === "sos");
    // eslint-disable-next-line 
  }, [drugDetail]);

  const handleClickOpen = () => {
    setOpen(true);
    setKey("DrugDetails");
    setDrugDetail(item);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const activeStepComponent = (k) => {
    setKey(k);
  };
  //====================== Update Drug =======================

  const UpdateDrugFunction = async (e) => {
    e.preventDefault();
    setLoader(true);

    const data = new FormData(e.target);
    const formData = new FormData();

    formData.append("drug_id", drugId);
    formData.append("drug_name", data.get("companyName"));
    formData.append("brand_name", data.get("brandName"));
    formData.append("drug_mrp", data.get("mrp"));
    formData.append("generic_name", data.get("genericName"));
    formData.append("medicine_category", midicineType);
    formData.append("medicine_category_type", oralView);
    formData.append(
      "strength_medicine",
      data.get("strengthNumber") + " " + data.get("strengthUnit")
    );
    formData.append(
      "frequency",
      data.get("morning") +
        ", " +
        data.get("after") +
        ", " +
        data.get("evening") +
        ", " +
        data.get("night") +
        ", " +
        data.get("sos") +
        ", null"
    );
    formData.append(
      "duration",
      data.get("durationTime") + " " + data.get("durationDay")
    );
    formData.append("realtion_of_food", data.get("relationWithFood"));
    formData.append("drug_instruction", data.get("drugInstruction"));
    formData.append("token", access_token);

    const req = await drugUpdate(formData);
    if (req.data) {
      setLoader(false);
      setOpen(false);
      message.success("Updated successfully");

      const updateDrug = {
        drug_name: data.get("companyName"),
        brand_name: data.get("brandName"),
        drug_instruction: data.get("drugInstruction"),
        drug_mrp: data.get("mrp"),
        duration: data.get("durationTime") + " " + data.get("durationDay"),
        frequency:
          data.get("morning") +
          ", " +
          data.get("after") +
          ", " +
          data.get("evening") +
          ", " +
          data.get("night") +
          ", " +
          data.get("sos") +
          ", null",
        generic_name: data.get("genericName"),
        medicine_category: midicineType,
        medicine_category_type: oralView,
        relation_of_food: data.get("relationWithFood"),
        strength_medicine:
          data.get("strengthNumber") + " " + data.get("strengthUnit"),
      };
      const allData = suggestionList;
      allData[index] = updateDrug;
      setSuggestionList(allData);
    }

    if (req.error) {
      setLoader(false);
      setOpen(false);
      message.error("Update Failed");
    }
  };


  return (
    <div>
      <Tooltip title="Edit Drug">
        <div
          className="iconsStyle edit"
          style={{ margin: "auto" }}
          onClick={handleClickOpen}
        >
          <RiFileEditFill />
        </div>
      </Tooltip>
      {/* <Button variant="contained" onClick={handleClickOpen}>
        Add Drug
      </Button> */}
      {drugDetail && (
        <Dialog
          className="AddDrugDialogue"
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px", // Set your width here
                minHeight: "500px",
              },
            },
          }}
        >
          <DialogContent>
            <div className="addDrugBoxHead">
              <h6>Update Drug</h6>
            </div>
            <div className="Facilities-Section2-tabpanel">
              <form onSubmit={UpdateDrugFunction}>
                <Tabs
                  defaultActiveKey="DrugDetails"
                  id="justify-tab-example"
                  className="mb-3"
                  justify
                  onSelect={(k) => activeStepComponent(k)}
                  activeKey={key}
                >
                  {/* ========== Drugs Details ============= */}
                  <Tab eventKey="DrugDetails" title="Drug Details">
                    <div className="Treatment-Con drugDetail">
                      <div className="input-style-1">
                        {/* <label>Brand Name</label> */}
                        <input
                          type="text"
                          name="brandName"
                          className="InputStyleMain"
                          placeholder="Enter Drug Name"
                          style={{ textTransform: "capitalize" }}
                          defaultValue={drugDetail?.brand_name}
                        />
                      </div>
                      <div className="input-style-1">
                        {/* <label>Company Name</label> */}
                        <input
                          type="text"
                          name="companyName"
                          className="InputStyleMain"
                          placeholder="Enter Company Name"
                          style={{ textTransform: "capitalize" }}
                          defaultValue={drugDetail?.drug_name}
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-style-1">
                            {/* <label>MRP</label> */}
                            <input
                              type="number"
                              name="mrp"
                              className="InputStyleMain"
                              placeholder="Enter MRP in Rupees "
                              defaultValue={drugDetail?.drug_mrp}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="input-style-1">
                            <input
                              type="text"
                              name="genericName"
                              className="InputStyleMain"
                              placeholder="Enter Generic Name"
                              defaultValue={drugDetail?.generic_name}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="oralButtons">
                            <span>Oral</span>
                            <span>Non Oral</span>
                            <div
                              onClick={toggleOral}
                              className={`${oralView} scrollButton`}
                            >
                              {oralView === "oral" ? "Oral" : "Non Oral"}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="drugTypeSection">
                            <div className="row">
                              {medicineShort
                                .filter((value) => value.type === oralView)
                                .map((valueMedicine, i) => (
                                  <div
                                    key={i}
                                    className="col-md-3 col-6 d-flex justify-content-start align-items-center"
                                  >
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      id={valueMedicine.name}
                                      name="medicineSelected"
                                      value={valueMedicine.name}
                                      checked={
                                        midicineType === valueMedicine.name
                                      }
                                      onChange={(e) =>
                                        setMidicineType(e.target.value)
                                      }
                                    />
                                    &nbsp;
                                    <label htmlFor={valueMedicine.name}>
                                      {valueMedicine.name}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  {/* ========== Other Detail ============= */}
                  <Tab eventKey="OtherDetails" title="Other Details">
                    <div className="Treatment-Con">
                      <div className="otherDetails">
                        {/* Frequency section */}
                        <div className="frequencyBox">
                          <div className="row strengthDetailSection">
                            <div className="col-4 mb-4">
                              {oralView === "oral" ? (
                                <div className="input-style-1 oneCapsuletext">
                                  <h6>1 {midicineType} = </h6>
                                </div>
                              ) : null}
                            </div>
                            <div className="col-4 mb-4">
                              <div className="input-style-1">
                                {/* <h6>Strength</h6> */}
                                <input
                                  type="number"
                                  name="strengthNumber"
                                  className="InputStyleMain"
                                  placeholder="Strength"
                                  defaultValue={
                                    drugDetail?.strength_medicine.split(" ")[0]
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="input-style-1 strengthUnitSelect">
                                {/* <h6>Strenght Unit</h6> */}
                                <select name="strengthUnit" id="strenghtUnit">
                                  <option
                                    value="mg"
                                    selected={
                                      drugDetail?.strength_medicine.split(" ")[1] ===
                                      "mg"
                                    }
                                  >
                                    mg
                                  </option>
                                  <option
                                    value="ml"
                                    selected={
                                      drugDetail?.strength_medicine.split(" ")[1] ===
                                      "ml"
                                    }
                                  >
                                    ml
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-4">
                              <h6>Frequency</h6>
                            </div>
                            <div className="col-6">
                              <div className="frequencyBtns">
                                <div className="row">
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <h6>M</h6>
                                  </div>
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <h6>A</h6>
                                  </div>
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <h6>E</h6>
                                  </div>
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <h6>N</h6>
                                  </div>
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <h6>SOS</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col d-flex justify-content-start align-items-center">
                              {/* Empty */}
                            </div>
                            <div className="col-4">{/* Empty */}</div>
                            <div className="col-6">
                              <div className="frequencyBtns">
                                <div className="row">
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <input
                                      name="null"
                                      value="null"
                                      type="checkbox"
                                      className="form-check-input d-none"
                                      checked={false}
                                      disabled={isSosChecked}
                                    />
                                    <input
                                      name="morning"
                                      value="morning"
                                      type="checkbox"
                                      className="form-check-input"
                                      onChange={(e) =>
                                        setFrequencyChecked({
                                          ...frequencyCheck,
                                          morning: e.target.checked
                                            ? e.target.value
                                            : null,
                                        })
                                      }
                                      checked={
                                        isSosChecked
                                          ? false
                                          : frequencyCheck.morning === "morning"
                                      }
                                      disabled={isSosChecked}
                                    />
                                  </div>
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <input
                                      name="after"
                                      value="after"
                                      type="checkbox"
                                      className="form-check-input"
                                      onChange={(e) =>
                                        setFrequencyChecked({
                                          ...frequencyCheck,
                                          afternoon: e.target.checked
                                            ? e.target.value
                                            : null,
                                        })
                                      }
                                      checked={
                                        isSosChecked
                                          ? false
                                          : frequencyCheck.afternoon === "after"
                                      }
                                      disabled={isSosChecked}
                                    />
                                  </div>
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <input
                                      name="evening"
                                      value="evening"
                                      type="checkbox"
                                      className="form-check-input"
                                      onChange={(e) =>
                                        setFrequencyChecked({
                                          ...frequencyCheck,
                                          evening: e.target.checked
                                            ? e.target.value
                                            : null,
                                        })
                                      }
                                      checked={
                                        isSosChecked
                                          ? false
                                          : frequencyCheck.evening === "evening"
                                      }
                                      disabled={isSosChecked}
                                    />
                                  </div>
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <input
                                      name="night"
                                      value="night"
                                      type="checkbox"
                                      className="form-check-input"
                                      onChange={(e) =>
                                        setFrequencyChecked({
                                          ...frequencyCheck,
                                          night: e.target.checked
                                            ? e.target.value
                                            : null,
                                        })
                                      }
                                      checked={
                                        isSosChecked
                                          ? false
                                          : frequencyCheck.night === "night"
                                      }
                                      disabled={isSosChecked}
                                    />
                                  </div>
                                  <div className="col d-flex justify-content-start align-items-center">
                                    <input
                                      name="sos"
                                      value="sos"
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={frequencyCheck.sos === "sos"}
                                      onChange={(e) => {
                                        setIsSosChecked((prev) =>
                                          prev === true ? false : true
                                        );
                                        setFrequencyChecked({
                                          ...frequencyCheck,
                                          sos: e.target.checked
                                            ? e.target.value
                                            : null,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Duration Section */}
                        <div className="durationSection">
                          <div className="row">
                            <div className="col-4">
                              <h6>Duration</h6>
                            </div>
                            <div className="col-3">
                              <input
                                type="number"
                                name="durationTime"
                                className="InputStyleMain"
                                defaultValue={drugDetail?.duration.split(" ")[0]}
                              />
                            </div>
                            <div className="col-3">
                              <div className="searchDiv">
                                <select name="durationDay" id="duration">
                                  <option
                                    value="days"
                                    selected={
                                      drugDetail?.duration.split(" ")[1] === "days"
                                    }
                                  >
                                    days
                                  </option>
                                  <option
                                    value="weeks"
                                    selected={
                                      drugDetail?.duration.split(" ")[1] === "weeks"
                                    }
                                  >
                                    weeks
                                  </option>
                                  <option
                                    value="months"
                                    selected={
                                      drugDetail?.duration.split(" ")[1] === "months"
                                    }
                                  >
                                    months
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Relation with Food */}
                        <div className="relationWithFood">
                          <h6>Relation with Food</h6>
                          <input
                            type="radio"
                            className="form-check-input"
                            id="Before"
                            name="relationWithFood"
                            value="Before"
                            defaultChecked={drugDetail?.relation_of_food === "Before"}
                          />
                            <label htmlFor="Before">Before</label>&nbsp;
                          <input
                            type="radio"
                            className="form-check-input"
                            id="After"
                            name="relationWithFood"
                            value="After"
                            defaultChecked={drugDetail?.relation_of_food === "After"}
                          />
                            <label htmlFor="After">After</label>&nbsp;
                          <input
                            type="radio"
                            className="form-check-input"
                            id="AnyTime"
                            name="relationWithFood"
                            value="AnyTime"
                            defaultChecked={drugDetail?.relation_of_food === "AnyTime"}
                          />
                            <label htmlFor="AnyTime">Any Time</label> 
                        </div>

                        {/* Drug Instruction */}
                        <div className="drugInstruction">
                          <h6>Drug Instructions</h6>
                          <div className="input-style-1">
                            <textarea
                              className="form-control TextareaStyleMain"
                              rows={2}
                              name="drugInstruction"
                              type="text"
                              placeholder="Enter drug instructions"
                              defaultValue={drugDetail?.drug_instruction}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>

                <div className="buttonList">
                  {key === "DrugDetails" ? (
                    <span onClick={handleClose} className="Cancelbtn">
                      Cancel
                    </span>
                  ) : (
                    <span
                      onClick={() => setKey("DrugDetails")}
                      className="Clickbtn"
                    >
                      Previous
                    </span>
                  )}

                  {key === "OtherDetails" ? (
                    loader ? (
                      <Button
                        className="submitBtn"
                        sx={{ width: 100 }}
                        disabled
                      >
                        <CircularProgress sx={{ size: 22, color: "#fff" }} />
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        value="submit"
                        className="submitBtn"
                      >
                        Update
                      </Button>
                    )
                  ) : (
                    <span
                      className="Clickbtn"
                      onClick={() => setKey("OtherDetails")}
                    >
                      Next
                    </span>
                  )}
                </div>
              </form>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
