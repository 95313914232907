import React, { useEffect, useState } from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import "../css/notificationSetting.css";
import email from "../Images/email.webp";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { getToken } from "../services/localStorageServices";
import { Button, Checkbox, Spin, message } from "antd";

export default function EmailSetting() {
  const [data, setData] = useState(null);
  const { access_token } = getToken();
  const [isUpdate, setIsUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  const fetchDataHandle = async () => {
    setLoading(true);
    const response = await fetch(
      "https://skdm.in/skddev/DrCrm/message_api/message_api_checked_list.php",
      {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
          type: "email",
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const req = await response.json();
    setData(req);
    setLoading(false);
  };

  useEffect(() => {
    const bounseTime = setTimeout(() => {
      fetchDataHandle();
    }, 500);
    return () => clearTimeout(bounseTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (index) => {
    const updatedData = [...data.template]; // Create a copy of the template array
    updatedData[index].enabled = updatedData[index].enabled === "1" ? "0" : "1"; // Toggle the enabled value
    setData({ ...data, template: updatedData }); // Update the state with the modified array
    setIsUpdate(false);
  };

  const handleUpdateChecked = async () => {
    setLoading(true);
    const response = await fetch(
      "https://skdm.in/skddev/DrCrm/message_api/message_api_checked_update.php",
      {
        method: "POST",
        body: JSON.stringify({
          token: access_token,
          type: "sms",
          updateLis: data.template,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );

    const req = await response.json();
    if (req.status) {
      message.success("Setting update successfully");
    } else {
      message.error("Failed to update setting");
    }
    setLoading(false);
  };
  return (
    <div>
      <NavbarComp />
      <SideBar />
      <Spin spinning={loading}>
        <div className="notificationSection navbarBodyMargin">
          <h3 className="pageTitle">Email Setting</h3>

          <div className="notificationDetails">
            <div className="notificationDetailLeft">
              <div className="notificationUsed">
                <h5>
                  <span>
                    <img src={email} alt="total Used" />
                    {data && data.totalUsedCredit}
                  </span>
                  <span>Total Email Send</span>
                </h5>
              </div>
              <div className="smsBookButtons">
                <NavLink to="/logs/email">
                  <button className="submitBtn">
                    <AiOutlineUnorderedList />
                    &nbsp;View Email Logs
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="notificationRight d-none d-xl-block">
              <img
                src="https://img.freepik.com/free-vector/email-campaign-concept-illustration_114360-1681.jpg?w=2000"
                alt=""
              />
            </div>
          </div>
          <hr />
          <div className="smsSetting">
            <h3>Select Email setting</h3>
            <div className="checkList">
              <div className="row">
                {data &&
                  data.template.map((value, i) => (
                    <div key={i} className="col-lg-4 col-md-6 col-12 mb-2">
                      <div className="form-check" key={i}>
                        <Checkbox
                          onChange={() => handleChange(i)}
                          value={value.UserTemplateID}
                          checked={value.enabled === "1"}
                        >
                          {value.template_text}
                        </Checkbox>
                      </div>
                    </div>
                  ))}
                <div className="col-12 mt-4">
                  <Button
                    disabled={isUpdate}
                    onClick={handleUpdateChecked}
                    className="Clickbtn"
                  >
                    Update Setting
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
