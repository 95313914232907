import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { getToken } from "../../services/localStorageServices";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./AddAppointmentBox.css";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { message } from "antd";
import axios from "axios";

const AppointmentModel = (props) => {
  const {
    setBookedAppointment,
    setFailedAppointment,
    setActiveDialogue,
    appointmentDetail,
    setAppointmentDetail,
    timeSlots,
    bookSlot,
    slotList,
  } = props;

  const { access_token } = getToken();
  const [ptientList, setPtientList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [validationMessage, setValidationMessage] = useState({
    nameValid: "",
    numberValid: "",
    dateOfBirthValid: "",
    genderValid: "",
    purposeValid: "",
    emailValid: "",
  });
  const [loading, setLoading] = useState(false);

  // ================================ Search Data ================================

  const [activeSearchList, setActiveSearchList] = useState({
    type: "",
    active: "",
  });

  const [searchData, setSearchData] = useState({
    search: "",
    type: "",
  });

  const fetchPatientList = async (search, type) => {
    if (navigator.onLine) {
      setLoader(true);
      if (search.length > 3) {
        setActiveSearchList({
          type: type,
          active: "true",
        });
      } else {
        setActiveSearchList({
          type: type,
          active: "false",
        });
      }

      try {
        const response = await fetch(
          "https://skdm.in/skddev/DrCrm/Patient_Profile_View.php",
          {
            method: "POST",
            body: JSON.stringify({
              token: access_token,
              patient_search: search,
              page: 1,
              limit: 10,
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        );
        const data = await response.json();
        if (Array.isArray(data.list)) {
          setPtientList(data.list);
          setLoader(false);
        } else {
          setActiveSearchList({
            type: type,
            active: "false",
          });
          setLoader(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const popupRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        // Clicked outside the popup, hide it
        setActiveSearchList({
          type: searchData.type,
          active: "false",
        });
      }
    };

    // Attach the event listener to the document
    document.addEventListener("click", handleOutsideClick);

    // Cleanup: remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line
  }, []);

  const patinetSearch = (e, type) => {
    const value = e.target.value;

    // Update the corresponding state based on the input type
    switch (type) {
      case "name":
        setAppointmentDetail({
          ...appointmentDetail,
          patientName: value,
        });
        break;

      case "PID":
        setAppointmentDetail({
          ...appointmentDetail,
          patientID: value,
        });
        break;

      case "number":
        setAppointmentDetail({
          ...appointmentDetail,
          patientNumber: value,
        });
        break;

      case "dateOfBirth":
        setAppointmentDetail({
          ...appointmentDetail,
          dateOfBirth: e,
        });
        break;

      case "email":
        setAppointmentDetail({
          ...appointmentDetail,
          patientEmail: value,
        });
        break;

      default:
        break;
    }

    // ================ Search Function =======================

    setSearchData({
      search: value,
      type: type,
    });
  };

  useEffect(() => {
    const delay = 500;
    let timeout;
    // Set a new timeout
    timeout = setTimeout(() => {
      if (searchData.search !== "") {
        fetchPatientList(searchData.search, searchData.type);
      }
    }, delay);

    return () => {
      // Cleanup: clear the timeout when the component unmounts or searchData changes
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  // ====================================== Set Patient Detail =============================


  const PatientDetailSet = async (type, value) => {
    setActiveSearchList({
      type: type,
      active: "false",
    });

    setAppointmentDetail({
      ...appointmentDetail,
      patientName: value.patient_first_name + " " + value.patient_last_name,
      patientID: value.pid,
      patientNumber: value.patient_phone_no,
      gender: value.patient_gender,
      dateOfBirth: value.pateint_date_of_birth,
      age: moment().diff(value.pateint_date_of_birth, "years"),
      dateOfBirthMonth:
        moment().diff(value.pateint_date_of_birth, "months") % 12,
      patientEmail: value.patient_email,
      address: value.patient_full_address,
      bloodGroup: value.blood_group,
      city: value.city,
      pincode: value.pincode,
    });
  };

  // ========================================== Book Appointment ================================

  const BookAppointment = async () => {
    const slotsTime = props.slot.split(" - ");
    const startTime = slotsTime[0];
    const endTime = slotsTime[1];

    // ==================== Name Validation ============================
    setValidationMessage((prevValidationMessage) => ({
      ...prevValidationMessage,
      nameValid:
        appointmentDetail.patientName !== "" ? "" : "Please enter the name",
    }));

    // ======================== Number Valid =====================
    setValidationMessage((prevValidationMessage) => ({
      ...prevValidationMessage,
      numberValid:
        appointmentDetail.patientNumber !== "" ? "" : "Please enter the number",
    }));

    // ======================== Date of Birth Validation =====================
    setValidationMessage((prevValidationMessage) => ({
      ...prevValidationMessage,
      dateOfBirthValid:
        appointmentDetail.dateOfBirth !== ""
          ? ""
          : "Please select date of birth",
    }));

    // ======================== Gender Validation =====================
    setValidationMessage((prevValidationMessage) => ({
      ...prevValidationMessage,
      genderValid:
        appointmentDetail.gender !== "" ? "" : "Please select the gender",
    }));

    try {
      if (
        appointmentDetail.patientName !== "" &&
        appointmentDetail.patientNumber !== "" &&
        appointmentDetail.dateOfBirth !== "" &&
        appointmentDetail.gender !== ""
      ) {
        setLoading(true);

        const dataRequest = {
          token: access_token,
          patient_Appointment_ID: "PID504",
          account_list_id_reff: appointmentDetail.hospitalId,
          patient_id_reff: appointmentDetail.patientID,
          patient_email: appointmentDetail.patientEmail,
          appointment_start_time: startTime,
          appointment_end_time: endTime,
          appointment_date: props.appointment_date,
          purpose_of_visit: appointmentDetail.purposeVisit,
          patient_first_name: appointmentDetail.patientName.split(" ")[0],
          patient_last_name: appointmentDetail.patientName.split(" ")[1],
          patient_gender: appointmentDetail.gender,
          pateint_date_of_birth: appointmentDetail.dateOfBirth,
          patient_full_address: appointmentDetail.address,
          pincode: appointmentDetail.pincode,
          city: appointmentDetail.city,
          patient_phone_no: appointmentDetail.patientNumber,
          blood_group: appointmentDetail.bloodGroup,
          visit_start_time: timeSlots[0],
          appointment_type: appointmentDetail.appointmentType,
          language: "English",
          appointment_status: "pending",
          allergy: [],
        };

        const headers = {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        };
        const response = await axios.post(
          "https://skdm.in/skddev/DrCrm/booking_appointment_Register.php",
          dataRequest,
          headers
        );
        bookSlot(
          props.appointment_date,
          appointmentDetail.hospitalId,
          0,
          20,
          appointmentDetail.appointmentType === "online" ? true : false,
          slotList
        );
        setLoading(false);
        message.success("Appointment Add Successfully");
        setBookedAppointment(true);
        setActiveDialogue(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Appointment Booking Failed");
      setFailedAppointment(true);
    }
  };

  function calculateBirthdate(ageInYears, ageInMonths) {
    // Create a moment object for the current date and time
    const now = moment();

    // Subtract the age in years and months from the current date to get the birthdate
    const birthdate = now
      .subtract(ageInYears, "years")
      .subtract(ageInMonths, "months");

    // Format the birthdate as a string in the desired format
    const formattedDate = birthdate.format("YYYY-MM-DD");

    // Return the formatted birthdate string
    return formattedDate;
  }

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={props.show}
        onClose={props.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="addAppointmentBox">
              <div className="popUpFormTitle">
                <span>Add Appointment: {props.slot}</span>
              </div>
              <div className="addAppointmentForm">
                <Box component="form" autoComplete="off">
                  <div className="row">
                    <div className="mb-3 col-sm-6 col-12">
                      <div className="input-style-1">
                        <label>
                          Patient Name
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="patinetName"
                          value={appointmentDetail.patientName}
                          onChange={(e) => patinetSearch(e, "name")}
                        />
                        <div
                          className={
                            activeSearchList.type === "name" &&
                            activeSearchList.active === "true"
                              ? ptientList === null
                                ? `searchBoxList`
                                : `activeSearchBox searchBoxList`
                              : `searchBoxList`
                          }
                        >
                          <div className="SearchData" ref={popupRef}>
                            {loader ? (
                              <CircularProgress
                                style={{
                                  fontSize: "1rem",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            ) : (
                              ptientList &&
                              ptientList.map((value, i) => (
                                <div
                                  className="patientList"
                                  key={value.pid}
                                  onClick={() =>
                                    PatientDetailSet("name", value)
                                  }
                                >
                                  <span className="patientId">{value.pid}</span>
                                  <div className="titleList">
                                    <h5>
                                      {value.patient_first_name +
                                        " " +
                                        value.patient_last_name}
                                    </h5>{" "}
                                    &nbsp; (
                                    <span className="phone">
                                      +91 {value.patient_phone_no}
                                    </span>
                                    )
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                      <span style={{ color: "red" }}>
                        {validationMessage.nameValid}
                      </span>
                    </div>
                    <div className="mb-3 col-sm-6 col-12">
                      <div className="input-style-1">
                        <label>Patient UID</label>
                        <input
                          type="text"
                          className="form-control"
                          name="patinetUID"
                          value={appointmentDetail.patientID}
                          onChange={(e) => patinetSearch(e, "PID")}
                        />
                        <div
                          className={
                            activeSearchList.type === "PID" &&
                            activeSearchList.active === "true"
                              ? ptientList === null
                                ? `searchBoxList`
                                : `activeSearchBox searchBoxList`
                              : `searchBoxList`
                          }
                        >
                          <div className="SearchData" ref={popupRef}>
                            {loader ? (
                              <CircularProgress
                                style={{
                                  fontSize: "1rem",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            ) : (
                              ptientList &&
                              ptientList.map((value, i) => (
                                <div
                                  className="patientList"
                                  key={value.pid}
                                  onClick={() => PatientDetailSet("PID", value)}
                                >
                                  <span className="patientId">{value.pid}</span>
                                  <div className="titleList">
                                    <h5>
                                      {value.patient_first_name +
                                        " " +
                                        value.patient_last_name}
                                    </h5>{" "}
                                    &nbsp; (
                                    <span className="phone">
                                      +91 {value.patient_phone_no}
                                    </span>
                                    )
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-sm-6 col-12">
                      <div className="input-style-1">
                        <label>
                          Patient Number
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          name="patinetNumber"
                          value={appointmentDetail.patientNumber}
                          onChange={(e) => patinetSearch(e, "number")}
                        />
                        <div
                          className={
                            activeSearchList.type === "number" &&
                            activeSearchList.active === "true"
                              ? ptientList === null
                                ? `searchBoxList`
                                : `activeSearchBox searchBoxList`
                              : `searchBoxList`
                          }
                        >
                          <div className="SearchData" ref={popupRef}>
                            {loader ? (
                              <CircularProgress
                                style={{
                                  fontSize: "1rem",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            ) : (
                              ptientList &&
                              ptientList.map((value, i) => (
                                <div
                                  className="patientList"
                                  key={value.pid}
                                  onClick={() =>
                                    PatientDetailSet("number", value)
                                  }
                                >
                                  <span className="patientId">{value.pid}</span>
                                  <div className="titleList">
                                    <h5>
                                      {value.patient_first_name +
                                        " " +
                                        value.patient_last_name}
                                    </h5>{" "}
                                    &nbsp; (
                                    <span className="phone">
                                      +91 {value.patient_phone_no}
                                    </span>
                                    )
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                      <span style={{ color: "red" }}>
                        {validationMessage.numberValid}
                      </span>
                    </div>

                    <div className="mb-3 col-sm-6 col-12">
                      <div className="input-style-1">
                        <label>Patient Email </label>
                        <input
                          type="tel"
                          className="form-control"
                          name="patinetNumber"
                          value={appointmentDetail.patientEmail}
                          onChange={(e) => patinetSearch(e, "email")}
                        />
                        <div
                          className={
                            activeSearchList.type === "email" &&
                            activeSearchList.active === "true"
                              ? ptientList === null
                                ? `searchBoxList`
                                : `activeSearchBox searchBoxList`
                              : `searchBoxList`
                          }
                        >
                          <div className="SearchData" ref={popupRef}>
                            {loader ? (
                              <CircularProgress
                                style={{
                                  fontSize: "1rem",
                                  width: "30px",
                                  height: "30px",
                                }}
                              />
                            ) : (
                              ptientList &&
                              ptientList.map((value, i) => (
                                <div
                                  className="patientList"
                                  key={value.pid}
                                  onClick={() =>
                                    PatientDetailSet("email", value)
                                  }
                                >
                                  <span className="patientId">{value.pid}</span>
                                  <div className="titleList">
                                    <h5>
                                      {value.patient_first_name +
                                        " " +
                                        value.patient_last_name}
                                    </h5>{" "}
                                    &nbsp; (
                                    <span className="phone">
                                      +91 {value.patient_phone_no}
                                    </span>
                                    )
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                      <span style={{ color: "red" }}>
                        {validationMessage.emailValid}
                      </span>
                    </div>
                    <div className="mb-3 col-12 col-md-6 col-sm-5">
                      <div className="input-style-1">
                        <label>
                          Date Of Birth
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              inputFormat="DD/MM/YYYY"
                              value={appointmentDetail.dateOfBirth}
                              disableFuture
                              onChange={(newValue) =>
                                setAppointmentDetail({
                                  ...appointmentDetail,
                                  dateOfBirth: moment(
                                    new Date(newValue)
                                  ).format("YYYY-MM-DD"),
                                  age: moment().diff(
                                    new Date(newValue),
                                    "years"
                                  ),
                                  dateOfBirthMonth:
                                    moment().diff(
                                      new Date(newValue),
                                      "months"
                                    ) % 12,
                                })
                              }
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      </div>
                      <span style={{ color: "red" }}>
                        {validationMessage.dateOfBirthValid}
                      </span>
                    </div>
                    <div className="mb-3 col-md-6 col-sm-7 col-12">
                      <div className="row">
                        <div className="col-6">
                          <div className="input-style-1">
                            <label></label>
                            <div className="row">
                              <div className="col-8 d-flex justify-content-center align-items-center">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="Month"
                                  value={appointmentDetail.age}
                                  onChange={(e) =>
                                    setAppointmentDetail({
                                      ...appointmentDetail,
                                      dateOfBirth: calculateBirthdate(
                                        e.target.value,
                                        appointmentDetail.dateOfBirthMonth
                                      ),
                                      age: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-4 p-0 m-0 d-flex justify-content-center align-items-center">
                                <span>Yrs</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-style-1">
                            <label htmlFor=""></label>
                            <div className="row">
                              <div className="col-8 d-flex justify-content-center align-items-center">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="Month"
                                  value={appointmentDetail.dateOfBirthMonth}
                                  onChange={(e) =>
                                    setAppointmentDetail({
                                      ...appointmentDetail,
                                      dateOfBirth: calculateBirthdate(
                                        appointmentDetail.age,
                                        e.target.value
                                      ),
                                      dateOfBirthMonth: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-4 p-0 m-0 d-flex justify-content-center align-items-center">
                                <span>Mnth</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 col-sm-6 col-12">
                      <div className="input-style-1">
                        <lable>
                          Gender
                          <span style={{ color: "red", marginLeft: "5px" }}>
                            *
                          </span>
                        </lable>
                        <select
                          name="gander"
                          className="form-control"
                          id=""
                          onChange={(e) =>
                            setAppointmentDetail({
                              ...appointmentDetail,
                              gender: e.target.value,
                            })
                          }
                        >
                          <option selected disabled value="">
                            Select Gander
                          </option>
                          {appointmentDetail.gender === "Male" ? (
                            <option value="Male" selected>
                              Male
                            </option>
                          ) : (
                            <option value="Male">Male</option>
                          )}
                          {appointmentDetail.gender === "Female" ? (
                            <option value="Female" selected>
                              Female
                            </option>
                          ) : (
                            <option value="Female">Female</option>
                          )}
                          {appointmentDetail.gender === "Others" ? (
                            <option value="Others" selected>
                              Others
                            </option>
                          ) : (
                            <option value="Others">Others</option>
                          )}
                        </select>
                      </div>
                      <span style={{ color: "red" }}>
                        {validationMessage.genderValid}
                      </span>
                    </div>
                    {/* <div className="mb-3 col-sm-6 col-12">
                      <div className="input-style-1">
                        <label>Blood Group<span style={{color:"red", marginLeft:"5px"}}>*</span></label>
                        <select
                          name="bloodGroup"
                          className="form-control"
                          id=""
                          onChange={(e) =>
                            setAppointmentDetail({
                              ...appointmentDetail,
                              bloodGroup: e.target.value,
                            })
                          }
                        >
                          <option>Select Blood Group</option>
                          {appointmentDetail.bloodGroup === "A+" ? (
                            <option value="A+" selected>
                              A+
                            </option>
                          ) : (
                            <option value="A+">A+</option>
                          )}
                          {appointmentDetail.bloodGroup === "A-" ? (
                            <option value="A-" selected>
                              A-
                            </option>
                          ) : (
                            <option value="A-">A-</option>
                          )}
                          {appointmentDetail.bloodGroup === "B+" ? (
                            <option value="B+" selected>
                              B+
                            </option>
                          ) : (
                            <option value="B+">B+</option>
                          )}
                          {appointmentDetail.bloodGroup === "B-" ? (
                            <option value="B-" selected>
                              B-
                            </option>
                          ) : (
                            <option value="B-">B-</option>
                          )}
                          {appointmentDetail.bloodGroup === "O+" ? (
                            <option value="O+" selected>
                              O+
                            </option>
                          ) : (
                            <option value="O+">O+</option>
                          )}
                          {appointmentDetail.bloodGroup === "O-" ? (
                            <option value="O-" selected>
                              O-
                            </option>
                          ) : (
                            <option value="O-">O-</option>
                          )}
                          {appointmentDetail.bloodGroup === "AB+" ? (
                            <option value="AB+" selected>
                              AB+
                            </option>
                          ) : (
                            <option value="AB+">AB+</option>
                          )}
                          {appointmentDetail.bloodGroup === "AB-" ? (
                            <option value="AB-" selected>
                              AB-
                            </option>
                          ) : (
                            <option value="AB-">AB-</option>
                          )}
                        </select>
                      </div>
                    </div> */}
                    <div className="mb-3 col-sm-6">
                      <div className="input-style-1">
                        <label>Purpose Of Visit</label>
                        <select
                          name="purpose"
                          className="form-control"
                          id=""
                          onChange={(e) =>
                            setAppointmentDetail({
                              ...appointmentDetail,
                              purposeVisit: e.target.value,
                            })
                          }
                        >
                          <option selected disabled value="">
                            Select Purpose Of Visit
                          </option>
                          <option value="Consultation">Consultation</option>
                          <option value="Follow Up">Follow Up</option>
                          <option value="Show Reports">Show Reports</option>
                          <option value="Certificate">Certificate</option>
                          <option value="Counseling">Counseling</option>
                        </select>
                      </div>
                      <span style={{ color: "red" }}>
                        {validationMessage.purposeValid}
                      </span>
                    </div>
                    <div className="mb-3 col-12">
                      <label>
                        Hospital Name
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </label>
                      <select
                        name="gander"
                        className="form-control"
                        id=""
                        disabled
                        onChange={(e) =>
                          setAppointmentDetail({
                            ...appointmentDetail,
                            hospitalId: e.target.value,
                          })
                        }
                      >
                        <option>Select Hospital</option>
                        {props.hospitalView
                          .filter(
                            (value) =>
                              value.list_of_account ===
                              appointmentDetail.hospitalId
                          )
                          .map((value, i) => (
                            <option
                              key={i}
                              value={value.list_of_account}
                              selected
                            >
                              {value.first_name}
                            </option>
                          ))}
                      </select>
                    </div>
                    {/* <div className="mb-3 col-12 ">
                      <div className="input-style-1">
                        <label>Address<span style={{color:"red", marginLeft:"5px"}}>*</span></label>
                        <input
                          type="text"
                          name="shortDesc"
                          className="form-control"
                          value={appointmentDetail.address}
                          onChange={(e) =>
                            setAppointmentDetail({
                              ...appointmentDetail,
                              address: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="mb-3 col-6 ">
                      <div className="input-style-1">
                        <label>City<span style={{color:"red", marginLeft:"5px"}}>*</span></label>
                        <input
                          type="text"
                          name="city"
                          className="form-control"
                          value={appointmentDetail.city}
                          onChange={(e) =>
                            setAppointmentDetail({
                              ...appointmentDetail,
                              city: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="mb-3 col-6 ">
                      <div className="input-style-1">
                        <label>Pincode<span style={{color:"red", marginLeft:"5px"}}>*</span></label>
                        <input
                          type="text"
                          name="pincode"
                          className="form-control"
                          value={appointmentDetail.pincode}
                          onChange={(e) =>
                            setAppointmentDetail({
                              ...appointmentDetail,
                              pincode: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </Box>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          {loading ? (
            <button className="Clickbtn" style={{ width: "150px" }}>
              {" "}
              <CircularProgress color="inherit" size={25} />
            </button>
          ) : (
            <button className="Clickbtn" onClick={BookAppointment}>
              Add Appointment
            </button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AppointmentModel;
