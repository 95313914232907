import { DatePicker, Divider, Input, Radio, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";

const { RangePicker } = DatePicker;

export default function Delay({ filtData }) {
  const [selectedType, setSelectedType] = useState("days");
  const [selectedValue, setSectedValue] = useState("<Select Date>");
  const [selectedTime, setSectedTime] = useState(null);

  useEffect(() => {
    const updateDate = (value, type, time) => {
      let dateData;
      switch (type) {
        case "days":
          dateData = value + (time ? " for " + time : "");
          break;
        case "week":
          dateData = "on " + value + (time ? " for " + time : "");
          break;
        case "singleDate":
          dateData =
            "on " +
            moment(new Date(value)).format("DD MMMM") +
            (time ? " for " + time : "");
          break;
        default:
          dateData = value + (time ? " for " + time : "");
          break;
      }

      filtData.setData(dateData);
    };

    updateDate(selectedValue, selectedType, selectedTime);
  }, [selectedValue, selectedType, selectedTime, filtData]);

  return (
    <div>
      <Select
        placeholder="Select Type"
        onChange={(value) => setSelectedType(value)}
        value={selectedType}
        style={{ width: "200px" }}
        options={[
          {
            label: "Days",
            value: "days",
          },
          {
            label: "Week",
            value: "week",
          },
          {
            label: "Single Date",
            value: "singleDate",
          },
        ]}
      />

      {selectedType === "days" ? (
        <>
          <Radio.Group
            className="mt-4"
            onChange={(e) => setSectedValue(e.target.value)}
          >
            <Radio value="Today">Today</Radio>
            <Radio value="Tomorrow">Tomorrow</Radio>
            <Radio value="Day after tomorrow">Day after tomorrow</Radio>
          </Radio.Group>
        </>
      ) : selectedType === "week" ? (
        <Select
          style={{ width: "150px", margin: "0 10px" }}
          placeholder="Select week name"
          onChange={(value) => setSectedValue(value)}
          options={[
            {
              label: "Monday",
              value: "Monday",
            },
            {
              label: "Tuseday",
              value: "Tuseday",
            },
            {
              label: "Wednesday",
              value: "Wednesday",
            },
            {
              label: "Thursday",
              value: "Thursday",
            },
            {
              label: "Friday",
              value: "Friday",
            },
            {
              label: "Saturday",
              value: "Saturday",
            },
            {
              label: "Sunday",
              value: "Sunday",
            },
          ]}
        />
      ) : selectedType === "singleDate" ? (
        <DatePicker
          className="formInput mx-3"
          format="DD MMM"
          onChange={(value) => setSectedValue(value)}
        />
      ) : selectedType === "rangeDate" ? (
        <RangePicker
          className="formInput mt-4"
          format="DD MMM"
          onChange={(value) => setSectedValue(value)}
        />
      ) : null}

      <Divider orientation="left">Delay for</Divider>
      <Input onChange={(e) => setSectedTime(e.target.value)} placeholder="Enter time" className="formInput" />
    </div>
  );
}
