import React from "react";
import "./App.css";
// import SideBar from "./components/Sidebar/SideBar";

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Hospitals from "./pages/Hospitals";
import Setting from "./pages/Setting";
import Login from "./pages/Login";
import Patinents from "./pages/Patinents";
import AddHospital from "./pages/AddHospital";
import NewProfile from "./pages/NewProfile";
import Handouts from "./pages/Handouts.jsx";
import EnterOtp from "./pages/EnterOtp";
import PatientProfile from "./pages/PatientProfile";
import Appointments from "./pages/Appointments";
import AppointmentDetail from "./pages/appointmentDetail";
import ViewHistory from "./pages/ViewHistory";
import Drugs from "./pages/Drugs";
import References from "./pages/References";
import PrescriptionTemplates from "./pages/PrescriptionTemplates";
import PrescriptionAttributes from "./pages/PrescriptionAttributes";
import AddAlleryBox from "./components/AddAllergyBox/AddAlleryBox";
import Referrals from "./pages/Referrals";
import ProcedureReferrals from "./pages/ProcedureReferrals.jsx";
import TestReferrals from "./pages/TestReferrals";
import { useSelector } from "react-redux";
import Forgot from "./pages/Forgot";
import Password from "./pages/Password";
import ErrorPage from "./pages/404Page";
import Certificate from "./components/Templates/certificate";
import ServiceList from "./pages/ServiceList";
import NoDataFound from "./pages/NoDataFound";
import NextAppointment from "./components/nextAppointment/nextAppointment";
import SMSsetting from "./pages/SMSsetting";
import EmailSetting from "./pages/EmailSetting";
import Logs from "./pages/Logs";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
  const { token } = useSelector((state) => state.auth);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={!token ? <Login /> : <Navigate to="/dashboard" />}
        />
        <Route path="/dashboard" element={!token ? <Login /> : <Dashboard />} />
        <Route path="/hospitals" element={!token ? <Login /> : <Hospitals />} />
        <Route path="/settings" element={!token ? <Login /> : <Setting />} />
        <Route path="/patients" element={!token ? <Login /> : <Patinents />} />
        <Route
          path="/addHospital/:id"
          element={!token ? <Login /> : <AddHospital />}
        />
        <Route
          path="/addHospital"
          element={!token ? <Login /> : <AddHospital />}
        />
        <Route
          path="/settings/doctor"
          element={!token ? <Login /> : <NewProfile />}
        />
        <Route
          path="/PatientProfile/:pid"
          element={!token ? <Login /> : <PatientProfile />}
        />
        <Route
          path="/PatientProfile/"
          element={!token ? <Login /> : <PatientProfile />}
        />
        <Route
          path="/enterOtp"
          element={!token ? <EnterOtp /> : <Navigate to="/dashboard" />}
        />
        <Route
          path="/appointment/:page"
          element={!token ? <Login /> : <Appointments />}
        />
        <Route
          path="/appointmentSteps/:pid/:hid/:apdate/:aptime/:type"
          element={
            !token ? (
              <Login />
            ) : (
              <DndProvider backend={HTML5Backend}>
                <AppointmentDetail />
              </DndProvider>
            )
          }
        />
        <Route path="/drugs" element={<Drugs />} />
        <Route
          path="/medicalHistory"
          element={!token ? <Login /> : <ViewHistory />}
        />
        <Route
          path="/referrals/references"
          element={!token ? <Login /> : <References />}
        />
        <Route
          path="/referrals/referrals"
          element={!token ? <Login /> : <Referrals />}
        />
        <Route
          path="/referrals/test"
          element={!token ? <Login /> : <TestReferrals />}
        />
        {/* <Route patj="/procedure" element={!token ? <Login /> : <ProcedureReferrals />} /> */}
        <Route
          path="/prescriptionTemplates"
          element={!token ? <Login /> : <PrescriptionTemplates />}
        />
        <Route
          path="/prescriptionAttributes"
          element={!token ? <Login /> : <PrescriptionAttributes />}
        />
        <Route
          path="/addAllergy"
          element={!token ? <Login /> : <AddAlleryBox />}
        />
        <Route
          path="/viewHistory/:pid/:name/:DOB/:gender"
          element={!token ? <Login /> : <ViewHistory />}
        />
        <Route path="/ForgotPassword" element={<Forgot />} />
        <Route path="/newPassword" element={<Password />} />
        <Route path="/handouts" element={<Handouts />} />
        <Route path="/certificate" element={<Certificate />} />
        <Route path="/procedure" element={<ProcedureReferrals />} />
        <Route path="/services" element={<ServiceList />} />
        <Route path="/noDataFound" element={<NoDataFound />} />
        <Route path="/nextAppoitnment" element={<NextAppointment />} />
        <Route path="/sms" element={<SMSsetting />} />
        <Route path="/email" element={<EmailSetting />} />
        <Route path="/logs/:type" element={<Logs />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {/* </SideBar> */}
    </BrowserRouter>
  );
}

export default App;
