import React, { useEffect, useRef, useState } from "react";
import SideBar from "../components/Sidebar/SideBar";
import NavbarComp from "../components/NavbarComp/NavbarComp";
import { AiFillSetting, AiOutlineCheck } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import AddHandoutBox from "../components/AddHandout/AddHandoutBox.jsx";
import "../css/Handouts.css";
import { BsArrowRight, BsFillImageFill } from "react-icons/bs";
import { IoAdd, IoSearchOutline } from "react-icons/io5";
import NoDataFound from "../pages/NoDataFound";
import { BiLinkAlt, BiSearch } from "react-icons/bi";
import { getToken } from "../services/localStorageServices";
import {
  useHandoutAddMutation,
  useHandoutDeleteMutation,
  useHandoutListDeleteMutation,
} from "../services/userAuthAPI";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { FaFilePdf, FaTrashAlt } from "react-icons/fa";
import { Box, Drawer } from "@mui/material";
import { message } from "antd";

const Handouts = () => {
  const [selectedCatogery, setSelectedCatogery] = useState(null);
  const { access_token } = getToken();
  const [isDisabled, setIsDisabled] = useState(true);
  const [newColumn, setNewColumn] = useState(false);
  const catogeryInputRef = useRef(null);
  const [handoutAdd] = useHandoutAddMutation();

  const [catogeryData, setCatogeryData] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [loaderData, setLoaderData] = useState(false);
  const [removeIds, setRemoveIds] = useState([]);
  const [removeIndex, setRemoveIndex] = useState([]);
  const [catogeryId, setCatogeryId] = useState();
  const [handoutDelete] = useHandoutDeleteMutation();
  const [handoutList, setHandoutList] = useState([]);
  const [handoutListStore, setHandoutListStore] = useState([]);
  const [loaderHandoutList, setLoaderHandoutList] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const [handoutListDelete] = useHandoutListDeleteMutation();

  // ===================== Module droawer =================
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // =============== Handout Search ======================
  const [handoutSearch, setHandoutSearch] = useState("");

  const fetchCatogeryList = async (catogeryID, dataSearch) => {
    try {
      setHandoutList([]);
      setLoaderHandoutList(true);
      const response = await fetch(
        "https://skdm.in/skddev/DrCrm/handout_view.php",
        {
          method: "POST",
          body: JSON.stringify({
            token: access_token,
            catogeryId: catogeryID,
            search: dataSearch,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseDataList = await response.json();

      if (response.ok) {
        setHandoutList(responseDataList);
        if (dataSearch) {
          setHandoutListStore(responseDataList);
        }
        setLoaderHandoutList(false);
      } else {
        setLoaderHandoutList(false);
        setHandoutList([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // ======================== Fetch Catogery ======================
  const [categorySerach, setCategorySerach] = useState("");
  const fetchData = async (dataSearch, isShow) => {
    setLoaderData(true);
    try {
      const response = await fetch(
        "https://skdm.in/skddev/DrCrm/handout_catogery_view.php",
        {
          method: "POST",
          body: JSON.stringify({
            token: access_token,
            search: dataSearch,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();

      if (response.ok) {
        setLoaderData(false);
        setCatogeryData(responseData);
        if (isShow === false) {
          setSelectedCatogery(responseData[0].handout_catogery_slug);
          setCatogeryId(responseData[0].handout_catogery_id);
          fetchCatogeryList(responseData[0].handout_catogery_id, "");
        }
      } else {
        setLoaderData(false);
        setCatogeryData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const delay = 1000;
    let timeout;
    // Set a new timeout
    timeout = setTimeout(() => {
      fetchData(categorySerach, false);
    }, delay);

    return () => {
      // Cleanup: clear the timeout when the component unmounts or searchData changes
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySerach]); // Empty dependency array to run the effect only once

  useEffect(() => {
    const delay = 1000;
    let timeout;
    // Set a new timeout
    timeout = setTimeout(() => {
      if (handoutSearch !== "") {
        fetchCatogeryList(catogeryId, handoutSearch);
      } else {
        if (handoutListStore.length > 0) {
          setHandoutList(handoutListStore);
        }
      }
    }, delay);


    return () => {
      // Cleanup: clear the timeout when the component unmounts or searchData changes
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catogeryId, handoutSearch]);

  // =============== Catogery Selected =====================
  const onCatogerySelect = (name, id) => {
    setHandoutList([]);
    setSelectedCatogery(name);
    setCatogeryId(id);
    fetchCatogeryList(id);
  };

  // ===================== Add Catogery =================
  const handleAddCatogery = async () => {
    const catogery = catogeryInputRef.current;
    if (catogery.value.length > 3) {
      setOpenBackDrop(true);
      const dataAddData = {
        handout_catogery_title: catogery.value,
        handout_catogery_slug: catogery.value
          .replace(/\s+/g, "-")
          .toLowerCase(),
        token: access_token,
      };
      const req = await handoutAdd(dataAddData);
      if (req.data) {
        message.success("Added Succesfully");
        setOpenBackDrop(false);
        const dataAdd = {
          handout_catogery_name: catogery.value,
          handout_catogery_slug: catogery.value
            .replace(/\s+/g, "-")
            .toLowerCase(),
        };
        setCatogeryData([dataAdd, ...catogeryData]);
        setNewColumn(false);
        catogery.value = "";
      }
    } else {
      alert("Please enter valid value");
    }
  };

  // ===================== Checked Id for remove =================
  const handleCheckboxChange = (event, index) => {
    const value = event.target.value;

    // Check if checkbox is checked or unchecked
    if (event.target.checked) {
      // Add the value to the array
      setRemoveIds((prevItems) => [...prevItems, value]);
      setRemoveIndex((prevItems) => [...prevItems, index]);
    } else {
      // Remove the value from the array
      setRemoveIds((prevItems) => prevItems.filter((item) => item !== value));
      setRemoveIndex((prevItems) => prevItems.filter((item) => item !== index));
    }
  };

  // ===================== Remove catogry API ===================

  const removeCatogory = async () => {
    setOpenBackDrop(true);

    const req = await handoutDelete({ id: removeIds });
    if (req.data) {
      const updatedItems = catogeryData;
      removeIndex.forEach((index) => {
        updatedItems.splice(index, 1);
      });

      setCatogeryData([...updatedItems]);
      message.success("Removed Succesfully");
      setOpenBackDrop(false);
      setRemoveIds([]);
    }
    if (req.error) {
      message.success("Removed Failed");
      setOpenBackDrop(false);
    }
  };

  // =========================== Remove Handout ===============================

  const removeHandout = async (id, index) => {
    if (window.confirm("Are sure you want to remove handout ?")) {
      setOpenBackDrop(false);

      const req = await handoutListDelete({
        token: access_token,
        handout_Id: id,
      });

      if (req.data) {
        const updatedItems = handoutList;
        updatedItems.splice(index, 1);
        setHandoutList([...updatedItems]);

        message.success("Removed Succesfully");
        setOpenBackDrop(false);
      }

      if (req.error) {
        message.success("Removed Failed");
        setOpenBackDrop(false);
      }
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <SideBar>
        <NavbarComp />
        <div className="handoutPage navbarBodyMargin">
          <div className="row">
            <div className="col-md-3 d-none d-lg-block">
              <div className="handoutCatogeryList">
                <div className="handoutsHead">
                  <h5>Handouts Catogery List</h5>
                  {removeIds && removeIds.length > 0 ? (
                    <button className="btn btn-danger" onClick={removeCatogory}>
                      Remove
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark"
                      onClick={() => setNewColumn(true)}
                    >
                      Add
                    </button>
                  )}
                </div>
                <div className="searchBox">
                  <input
                    type="text"
                    placeholder="Search Catogery"
                    name=""
                    id=""
                    onChange={(e) => setCategorySerach(e.target.value)}
                    className="form-control"
                  />
                  <IoSearchOutline />
                  <span>Enter catogery name for find</span>
                  <hr />
                </div>
                <div className="dataList">
                  {newColumn ? (
                    <div className="newColumn">
                      <IoAdd />
                      <input
                        type="text"
                        name=""
                        placeholder="Catogery title"
                        id=""
                        ref={catogeryInputRef}
                        onInput={(e) =>
                          setIsDisabled(
                            e.target.value.length > 0 ? false : true
                          )
                        }
                      />
                      <button
                        className="add"
                        onClick={handleAddCatogery}
                        disabled={isDisabled}
                      >
                        <AiOutlineCheck />
                      </button>
                      <button
                        className="close"
                        onClick={() => setNewColumn(false)}
                      >
                        <MdOutlineClose />
                      </button>
                    </div>
                  ) : null}
                  {loaderData ? (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <CircularProgress
                        color="primary"
                        sx={{
                          width: "30px !important",
                          height: "30px !important",
                        }}
                      />
                      &nbsp;Finding ....
                    </span>
                  ) : null}
                  {catogeryData && catogeryData.length > 0 ? (
                    catogeryData.map((value, i) => (
                      <div className="cardData" key={i}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={value.handout_catogery_id}
                            id="flexCheckDefault"
                            onChange={(e) => handleCheckboxChange(e, i)}
                          />
                        </div>
                        <div
                          className={
                            value.handout_catogery_slug === selectedCatogery
                              ? `catogeryListCard Listactive`
                              : `catogeryListCard`
                          }
                          onClick={() =>
                            onCatogerySelect(
                              value.handout_catogery_slug,
                              value.handout_catogery_id
                            )
                          }
                        >
                          <h5>{value.handout_catogery_name}</h5>
                          <BsArrowRight />
                        </div>
                      </div>
                    ))
                  ) : loaderData ? null : (
                    <h5>No Data Found</h5>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-12">
              <div className="handoutListData">
                <div className="handoutListHeader d-block d-md-none">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1 className="pageTitle">{selectedCatogery}</h1>
                    <AddHandoutBox catogeryId={catogeryId} />
                  </div>
                  <div className="searchHandout mt-3">
                    <input
                      type="text"
                      placeholder="Search here..."
                      name=""
                      id=""
                      onChange={(e) => setHandoutSearch(e.target.value)}
                    />
                    <span>
                      <BiSearch />
                    </span>
                  </div>
                </div>
                <div className="handoutListHeader d-md-flex d-none">
                  <div className="searchHandout">
                    <input
                      type="text"
                      placeholder="Search here..."
                      name=""
                      id=""
                      onChange={(e) => setHandoutSearch(e.target.value)}
                    />
                    <span>
                      <BiSearch />
                    </span>
                  </div>
                  <AddHandoutBox catogeryId={catogeryId} />
                </div>
                <hr />
                <div className="row">
                  {loaderHandoutList ? (
                    <div
                      style={{
                        width: "100%",
                        height: "50vh",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {" "}
                      <CircularProgress
                        color="primary"
                        sx={{
                          width: "60px !important",
                          height: "60px !important",
                        }}
                      />
                      <h3>Finding List...</h3>
                    </div>
                  ) : null}
                  {handoutList && handoutList.length > 0 ? (
                    handoutList.map((valueData, i) => (
                      <div className="col-md-3 col-6" key={i}>
                        <div className="handoutcard">
                          <div className="handoutImage">
                            <img
                              src={valueData.handout_banner}
                              alt={valueData.slug}
                            />
                          </div>
                          <div className="handoutTitle">
                            <h4>{valueData.handout_title}</h4>
                          </div>
                          <div className="handoutButtonSection">
                            {valueData.handout_type === "Link" ? (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                alt={valueData.slug}
                                href={valueData.handout_file}
                              >
                                <button className="link">
                                  <BiLinkAlt />
                                </button>
                              </a>
                            ) : valueData.handout_type === "PDF" ? (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                alt={valueData.slug}
                                href={valueData.handout_file}
                              >
                                <button className="pdf">
                                  <FaFilePdf />
                                </button>
                              </a>
                            ) : (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                alt={valueData.slug}
                                href={valueData.handout_file}
                              >
                                <button className="image">
                                  <BsFillImageFill />
                                </button>
                              </a>
                            )}
                            {/* <button>
                              <FaEdit />
                            </button> */}
                            <button
                              onClick={() =>
                                removeHandout(valueData.handout_id, i)
                              }
                            >
                              <FaTrashAlt />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : loaderHandoutList ? null : (
                    <NoDataFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideBar>

      {/* ========================= Fixed Button ========================= */}

      <div className="categoryShow d-block d-lg-none">
        <button onClick={toggleDrawer("right", true)}>
          <AiFillSetting />
        </button>
      </div>

      {/* ====================== Drawer ============================ */}

      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Box sx={{ width: 350, overflowX: "hidden" }} role="presentation">
          <div className="handoutCatogeryList p-2">
            <div className="handoutsHead">
              <h1 className="pageTitle">Handouts Catogery List</h1>
              {removeIds && removeIds.length > 0 ? (
                <button className="btn btn-danger" onClick={removeCatogory}>
                  Remove
                </button>
              ) : (
                <button
                  className="btn btn-dark"
                  onClick={() => setNewColumn(true)}
                >
                  Add
                </button>
              )}
            </div>
            <div className="searchBox">
              <input
                type="text"
                placeholder="Search Catogery"
                name=""
                id=""
                onChange={(e) => fetchData(e.target.value, false)}
                className="form-control"
              />
              <IoSearchOutline />
              <span>Enter catogery name for find</span>
              <hr />
            </div>
            <div className="dataList">
              {newColumn ? (
                <div className="newColumn">
                  <IoAdd />
                  <input
                    type="text"
                    name=""
                    placeholder="Catogery title"
                    id=""
                    ref={catogeryInputRef}
                    onInput={(e) =>
                      setIsDisabled(e.target.value.length > 0 ? false : true)
                    }
                  />
                  <button
                    className="add"
                    onClick={handleAddCatogery}
                    disabled={isDisabled}
                  >
                    <AiOutlineCheck />
                  </button>
                  <button className="close" onClick={() => setNewColumn(false)}>
                    <MdOutlineClose />
                  </button>
                </div>
              ) : null}
              {loaderData ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <CircularProgress
                    color="primary"
                    sx={{
                      width: "30px !important",
                      height: "30px !important",
                    }}
                  />
                  &nbsp;Finding ....
                </span>
              ) : null}
              {catogeryData && catogeryData.length > 0 ? (
                catogeryData.map((value, i) => (
                  <div className="cardData" key={i}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={value.handout_catogery_id}
                        id="flexCheckDefault"
                        onChange={(e) => handleCheckboxChange(e, i)}
                      />
                    </div>
                    <div
                      className={
                        value.handout_catogery_slug === selectedCatogery
                          ? `catogeryListCard Listactive`
                          : `catogeryListCard`
                      }
                      onClick={() =>
                        onCatogerySelect(
                          value.handout_catogery_slug,
                          value.handout_catogery_id
                        )
                      }
                    >
                      <h5>{value.handout_catogery_name}</h5>
                      <BsArrowRight />
                    </div>
                  </div>
                ))
              ) : loaderData ? null : (
                <h5>No Data Found</h5>
              )}
            </div>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default Handouts;
