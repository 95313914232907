import {
  Button,
  DatePicker,
  Divider,
  Radio,
  Select,
  Spin,
  message,
} from "antd";
import React, { useState } from "react";
import { TimePicker } from "antd";
import moment from "moment";
import axios from "axios";
import { getToken } from "../../services/localStorageServices";

const { RangePicker } = DatePicker;

export default function BlockSlots({ timeSlots, hospitalId, handleCancel, setIsModalOpen }) {
  const [selectedType, setSelectedType] = useState("today");
  const [selectedValue, setSectedValue] = useState();
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectTime, setSelectTime] = useState();
  const { access_token } = getToken();  
  const appointment_list = JSON.parse(
    sessionStorage.getItem("appointment_list")
  );

  const [selectDate, setSelectDate] = useState();

  const handleSlotPush = (value) => {
    const index = selectedSlots.findIndex((valueSlot) => valueSlot === value);

    if (index !== -1) {
      const previousData = [...selectedSlots];
      previousData.splice(index, 1);
      setSelectedSlots(previousData);
    } else {
      setSelectedSlots((prev) => [...prev, value]);
    }
  };

  const handleSelectType = (e) => {
    setSectedValue(e.target.value);
    setSelectTime(null);

    if (e.target.value === "all") {
      setSelectedSlots(timeSlots);
    }

    if (e.target.value === "available") {
      const availableSlots = timeSlots.filter((timeSlot) => {
        // Check if the time slot is not in any of the appointment_start_time in appointment_list
        return !appointment_list.some(
          (appointment) => appointment.appointment_start_time === timeSlot
        );
      });
      setSelectedSlots(availableSlots);
    }

    if (e.target.value === "custome") {
      setSelectedSlots([]);
    }
  };



  const [loading, setLoading] = useState(false);

  const handleSubmitBlock = async () => {
    try {
      setLoading(true);
      let fromDate;
      let toDate;

      switch (selectedType) {
        case "today":
          fromDate = moment(new Date()).format("YYYY-MM-DD");
          toDate = moment(new Date()).format("YYYY-MM-DD");
          break;
        case "singleDate":
          fromDate = moment(new Date(selectDate)).format("YYYY-MM-DD");
          toDate = moment(new Date(selectDate)).format("YYYY-MM-DD");
          break;
        case "rangeDate":
          fromDate = moment(new Date(selectDate[0])).format("YYYY-MM-DD");
          toDate = moment(new Date(selectDate[1])).format("YYYY-MM-DD");
          break;
        default:
          fromDate = moment(new Date()).format("YYYY-MM-DD");
          toDate = moment(new Date()).format("YYYY-MM-DD");
          break;
      }

      const formData = new FormData();
      formData.append("toDate", toDate);
      formData.append("fromDate", fromDate);
      formData.append("times", JSON.stringify(selectedSlots));
      formData.append("dates", JSON.stringify(handleGetDateList(fromDate, toDate)));
      formData.append("token", access_token);
      formData.append("hospitalId", hospitalId);

      const resp = await axios.post(
        "https://skdm.in/skddev/DrCrm/block-slot.php",
        formData
      );

      if (resp) {
        setLoading(false);
        message.success("Slots Block Successfully");
        sessionStorage.setItem("appointment_list", "[]");
        setSelectedSlots([]);
        setIsModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Unable to submit please try again !");
    }
  };
  const handleGetDateList = (fromDate, toDate) => {
    const datesInRange = [];
    const currentDate = new Date(fromDate);
    const endDateObj = new Date(toDate);
    while (currentDate <= endDateObj) {
      datesInRange.push(currentDate.toISOString().split("T")[0]); // Push the date in YYYY-MM-DD format
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }
    return datesInRange;
  };
  

  return (
    <Spin spinning={loading}>
      <div className="selectDetail d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column w-100">
          <h6>Select Type</h6>
          <Select
            placeholder="Select Type"
            className="mt-2"
            onChange={(value) => setSelectedType(value)}
            style={{ width: "100%" }}
            value={selectedType}
            options={[
              {
                label: "Today",
                value: "today",
              },
              {
                label: "Single Date",
                value: "singleDate",
              },
              {
                label: "Range Date",
                value: "rangeDate",
              },
            ]}
          />
        </div>
        {selectedType === "singleDate" ? (
          <div className="d-flex flex-column w-100 mx-4">
            {" "}
            <h6>Select Date</h6>
            <DatePicker
              className="formInput mt-2"
              style={{ width: "100%" }}
              format="DD MMM"
              onChange={(value) => setSelectDate(value)}
            />
          </div>
        ) : selectedType === "rangeDate" ? (
          <div className="d-flex flex-column w-100 mx-4">
            <h6>Select Date</h6>
            <RangePicker
              className="formInput mt-2"
              style={{ width: "100%" }}
              format="DD MMM"
              onChange={(value) => setSelectDate(value)}
            />
          </div>
        ) : null}
      </div>
      <Divider orientation="left">Select Slot</Divider>

      <div className="row mt-4">
        <div className="col-md-6">
          <Radio.Group onChange={(e) => handleSelectType(e)}>
            <Radio value="all">All Slots</Radio>
            <Radio value="available">Available Slots</Radio>
            <Radio value="custome">Custome</Radio>
          </Radio.Group>
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-between">
          <strong>
            <span>Total Slots</span>
            <span>({timeSlots?.length})</span>
          </strong>
          <strong>
            <span>Selected Slots</span>
            <span>({selectedSlots.length})</span>
          </strong>
          <strong>
            <span>Blocked Slots</span>
            <span>
              (
              {
                appointment_list.filter(
                  (value) => value.appointment_status === "block"
                )?.length
              }
              )
            </span>
          </strong>
        </div>
      </div>
      {selectedValue === "custome" && (
        <div className="mt-4">
          <TimePicker.RangePicker
            use12Hours
            format="h:mm A"
            className="formInput w-100"
            style={{ maxWidth: "400px" }}
            onChange={(value) => setSelectTime(value)}
          />
        </div>
      )}

      <div className="slotData d-flex align-items-center justify-content-center w-100 mt-4">
        <div className="slotsList">
          {selectTime
            ? timeSlots
                ?.filter(
                  (value) =>
                    value >=
                      moment(new Date(selectTime[0])).format("hh:mm A") &&
                    value <= moment(new Date(selectTime[1])).format("hh:mm A")
                )
                ?.slice(0, 28)
                .map((value, i) => (
                  <button
                    key={i}
                    onClick={() => handleSlotPush(value)}
                    
                    className={`slotCard ${
                      appointment_list?.some(
                        (valueData) =>
                          valueData.appointment_status === "block" && valueData.appointment_start_time === value
                      )
                        ? "blockedSlot"
                        : selectedSlots.some((valueSlot) => value === valueSlot)
                        ? "selectedSlot"
                        : "availableSlot"
                    }
              `}
                  >
                    {value}
                  </button>
                ))
            : timeSlots?.slice(0, 28).map((value, i) => (
                <button
                  key={i}
                  onClick={() => handleSlotPush(value)}
                  className={`slotCard ${
                    appointment_list?.some(
                      (valueData) =>
                        valueData.appointment_status === "block" && valueData.appointment_start_time === value
                    )
                      ? "blockedSlot"
                      : selectedSlots.some((valueSlot) => value === valueSlot)
                      ? "selectedSlot"
                      : "availableSlot"
                  }
              `}

              disabled={appointment_list?.some(
                (valueData) =>
                  valueData.appointment_status === "block" && valueData.appointment_start_time === value
              )}
                >
                  {value}
                </button>
              ))}
        </div>
      </div>
      <Divider />
      <div className="buttonGroups mt-3">
        <Button className="Cancelbtn" onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmitBlock} className="Clickbtn">
          Submit
        </Button>
      </div>
    </Spin>
  );
}
