import React, { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { FaStar } from "react-icons/fa";
import { getToken } from "../../services/localStorageServices";
import { IoImage } from "react-icons/io5";
import { IoLinkSharp } from "react-icons/io5";
import { MdPictureAsPdf } from "react-icons/md";
import { useSideBar } from "../../context/sideBar";

export default function HandoutComp({ addIntoLocal }) {
  const [handoutDataList, setHandoutDataList] = useState([]);

  const { selectedHandouts, setSelectedHandouts } = useSideBar();

  const handoutStyle = {
    padding: "5px 10px",
    background: "#808080",
    color: "#fff",
    margin: "5px",
    borderRadius: "50px",
    border: "unset",
    fontSize: "12px",
    transition: "0.3s ease-in-out",
    textTransform: "capitalize",
  };

  const handoutActiveStyle = {
    padding: "5px 10px",
    background: "green",
    color: "#fff",
    margin: "5px",
    borderRadius: "50px",
    border: "unset",
    fontSize: "12px",
    transition: "0.3s ease-in-out",
    textTransform: "capitalize",
  };
  const handleSelectHandout = (data) => {
    setSelectedHandouts((prevSelectedHandouts) => {
      const isDataSelected = prevSelectedHandouts.some(
        (valueData) => data.handout_id === valueData.handout_id
      );

      if (isDataSelected) {
        // If data is already selected, remove it
        return prevSelectedHandouts.filter((item) => item !== data);
      } else {
        // If data is not selected, add it
        return [...prevSelectedHandouts, data];
      }
    });

    addIntoLocal();
  };

  const { access_token } = getToken();
  // =============== Handout Search ======================
  const [handoutSearch, setHandoutSearch] = useState("");

  const fetchCatogeryList = async (dataSearch) => {
    try {
      const response = await fetch(
        "https://skdm.in/skddev/DrCrm/handout_view.php",
        {
          method: "POST",
          body: JSON.stringify({
            token: access_token,
            search: dataSearch,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseDataList = await response.json();

      if (response.ok) {
        setHandoutDataList(responseDataList);
      } else {
        setHandoutDataList([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const delay = 1000;
    let timeout;
    // Set a new timeout
    timeout = setTimeout(() => {
      fetchCatogeryList(handoutSearch);
    }, delay);

    return () => {
      // Cleanup: clear the timeout when the component unmounts or searchData changes
      clearTimeout(timeout);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handoutSearch]);

  const dataType = [
    {
      name: "Link",
      icon: <IoLinkSharp />,
    },
    {
      name: "Image",
      icon: <IoImage />,
    },
    {
      name: "PDF",
      icon: <MdPictureAsPdf />,
    },
  ];

  return (
    <div className="inputBox">
      <div className="inputBoxHead">
        <div className="inputBoxTitle handoutInputTitle">
          <h6>
            Handouts/ Videos{" "}
            <span>Please use the search pan to add Handouts </span>
          </h6>
        </div>

        <div className="inputBoxInput">
          <div className="position-relative">
            <div className="inputpadding">
              <div className="iconadde">
                <div className="iconsStyle search">
                  <BiPlus />
                </div>
              </div>
              <input
                autoComplete="off"
                type="text"
                name="name"
                placeholder="Search here..."
                onChange={(e) => {
                  setHandoutSearch(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="selectedList" style={{ padding: "15px" }}>
        {handoutDataList.map((value, i) => (
          <button
            onClick={() => handleSelectHandout(value)}
            style={
              selectedHandouts.some(
                (valueData) => value.handout_id === valueData.handout_id
              )
                ? handoutActiveStyle
                : handoutStyle
            }
            key={i}
          >
            <div className="d-flex justify-content-center align-items-center">
              {selectedHandouts.some(
                (valueData) => value.handout_id === valueData.handout_id
              ) && <FaStar style={{ color: "gold", fontSize: "10px" }} />}
              &nbsp;
              {value.handout_title} &nbsp;
              {dataType.map(
                (valueData) =>
                  valueData.name === value.handout_type && valueData.icon
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}
