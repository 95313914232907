import { Button, Divider, Radio, Select, Spin, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import NotAvailable from "./SubDetailSms/NotAvailable";
import Delay from "./SubDetailSms/Delay";
import Compaign from "./SubDetailSms/Compaign";
import Send from "./SubDetailSms/Send";
import { getToken } from "../../services/localStorageServices";
import Cancelled from "./SubDetailSms/Cancelled";
import moment from "moment";
import axios from "axios";

export default function SmsSend({hospital, setIsModalOpen, handleCancel}) {
  const [template, setTemplate] = useState("");
  const { access_token } = getToken();
  const [selectedPatient, setSelectedPatient] = useState([]);
  const doctorDetails = JSON.parse(sessionStorage.getItem("doctorData"));
  const [notAvailableDate, setNotAvailableDate] = useState("<Select Date>");
  const [delayDate, setDelayDate] = useState("<Select Date>");
  const [compaignDetail, setCompaignDetail] = useState({
    date: "<date>",
    name: "<name>",
    contact: "<contact detail>",
  });
  const [sendLink, setSendLink] = useState({
    link: "<link>",
    name: "<name>",
  });

  const [canceledDate, setCancelledDate] = useState({
    hospitalName: hospital,
    date: "<Date>",
  });

  const [selectedPatientDetail, setSelectedPatientDetail] = useState([]);

  const smsData = [
    {
      type: "notAvailable",
      title:"Doctor Unavailable",
      use:1,
      message:
        "Dear Patient, I'll be unavailable " +
        notAvailableDate +
        ". Best Regards, Dr. " +
        doctorDetails.first_name +
        " " +
        doctorDetails.last_name +
        " & Team Search My Doctor.",
      setData: setNotAvailableDate,
      data: notAvailableDate,
    },
    {
      type: "delay",
      title:"Doctor will be delay",
      use:1,
      message:
        "Dear Patient, Apologies for there may be a delay " +
        delayDate +
        ". Best Regards, Dr. " +
        doctorDetails.first_name +
        " " +
        doctorDetails.last_name +
        " & Team Search My Doctor.",
      setData: setDelayDate,
      data: delayDate,
    },
    {
      type: "compaign",
      title:"Campaign Awarness",
      use:2,
      message:
        "Dear Patient, Exciting news! We're launching the " +
        compaignDetail.name +
        " health campaign, starting from " +
        compaignDetail.date +
        ". For more information, please contact at " +
        compaignDetail.contact +
        ". Best regards, Dr. " +
        doctorDetails.first_name +
        " " +
        doctorDetails.last_name +
        " & Team Search My Doctor.",
      setData: setCompaignDetail,
      data: compaignDetail,
    },
    {
      type: "send",
      title: sendLink.name +" Link Send",
      use:1,
      message:
        "Dear Patient, I am sharing my " +
        sendLink.name +
        " link: " +
        sendLink.link +
        ". Let me know if you have any questions. Best Reagrds, Dr. " +
        doctorDetails.first_name +
        " " +
        doctorDetails.last_name +
        " & Team Search My Doctor.",
      setData: setSendLink,
      data: sendLink,
    },
    {
      type: "cancelled",
      title:"Appointment Cancelled by doctor",
      use:1,
      message:
        "Dear Patient, your appointment "+canceledDate.date+" at  " +
        canceledDate.hospitalName +
        " has been cancelled. Apologies for inconvenience. Best Resgards, Dr. " +
        doctorDetails.first_name +
        " " +
        doctorDetails.last_name +
        " & Team Search My Doctor.",
      setData: setCancelledDate,
      data: canceledDate,
    },
  ];

  const detailUI = (selected, data) => {
    let selectedUI;
    switch (selected) {
      case "notAvailable":
        selectedUI = (
          <NotAvailable
            filtData={data?.find((value) => value.type === template)}
          />
        );
        break;
      case "delay":
        selectedUI = (
          <Delay filtData={data?.find((value) => value.type === template)} />
        );
        break;
      case "compaign":
        selectedUI = (
          <Compaign filtData={data?.find((value) => value.type === template)} />
        );
        break;
      case "send":
        selectedUI = (
          <Send filtData={data?.find((value) => value.type === template)} />
        );
        break;
      case "cancelled":
        selectedUI = (
          <Cancelled
            filtData={data?.find((value) => value.type === template)}
            hospital={hospital}
          />
        );
        break;
      default:
        break;
    }

    return selectedUI;
  };

  // =================== Patient List ==============
  const [loader, setLoader] = useState(false);
  const [ptientList, setPtientList] = useState([]);
  const [searchPatient, setSearchPatient] = useState(null);

  const fetchPatient = async (search) => {
    try {
      const response = await fetch(
        "https://skdm.in/skddev/DrCrm/Patient_Profile_View.php",
        {
          method: "POST",
          body: JSON.stringify({
            token: access_token,
            patient_search: search,
            page: 1,
            limit: 10,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      const data = await response.json();
      if (Array.isArray(data.list)) {
        setPtientList(data.list);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const renderTime = setTimeout(() => {
      if (searchPatient) {
        fetchPatient(searchPatient);
      }
    }, 300);

    return () => clearTimeout(renderTime);

    // eslint-disable-next-line
  }, [searchPatient]);

  // ========================= Submit Form =====================

  const [loading, setLoading] = useState(false);

  const handleSubmitForm = async () => {
    try {
      setLoading(true);

      let toDate;

      switch (selectedPatientDetail) {
        case "oneWeek":
          toDate = moment(new Date()).subtract(1, "weeks").format("YYYY-MM-DD");
          break;
        case "twoWeek":
          toDate = moment(new Date()).subtract(2, "weeks").format("YYYY-MM-DD");
          break;
        case "oneMonth":
          toDate = moment(new Date())
            .subtract(1, "months")
            .format("YYYY-MM-DD");
          break;
        case "twoMonth":
          toDate = moment(new Date())
            .subtract(2, "months")
            .format("YYYY-MM-DD");
          break;
        case "sixMonth":
          toDate = moment(new Date())
            .subtract(6, "months")
            .format("YYYY-MM-DD");
          break;
        case "oneYear":
          toDate = moment(new Date()).subtract(1, "years").format("YYYY-MM-DD");
          break;
        case "todayAppointment":
          toDate = moment(new Date()).format("YYYY-MM-DD");
          break;
        default:
          break;
      }

      const findPatientIds = selectedPatient.map((value) => {
        const parts = value.split(" - ");
        const phoneNumberAndPID = parts[1];
        const phoneNumberPIDParts = phoneNumberAndPID.split(" ");
        const phoneNumber = phoneNumberPIDParts[0]; // 9769495749
        return phoneNumber;
      });

      const formData = new FormData();
      formData.append("smsType", template);
      formData.append("sendType", selectedPatientDetail);
      formData.append("patienList", JSON.stringify(findPatientIds));
      formData.append("dateTo", moment(new Date()).format("YYYY-MM-DD"));
      formData.append("dateFrom", toDate);
      formData.append("token", access_token);
      formData.append("use",  smsData?.find((value) => template === value.type)?.use);
      formData.append(
        "message",
        smsData?.find((value) => template === value.type)?.message
      );
      formData.append(
        "title",
        smsData?.find((value) => template === value.type)?.title
      );

      const resp = await axios.post(
        "https://skdm.in/skddev/DrCrm/bulk-sms-send.php",
        formData
      );

      if (resp) {
        setLoading(false);
        message.success("SMS sent successfully");
        setIsModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("Sorry we unable send sms please try again !");
    }
  };
  
  return (
    <Spin spinning={loading}>
      <div className="row">
        <div className="col-md-6">
          <Radio.Group
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
          >
            <div className="row">
              <div className="col-md-4">
                <Radio value="delay">Delay</Radio>
              </div>
              <div className="col-md-4">
                <Radio value="compaign">Campaign</Radio>
              </div>
              <div className="col-md-4">
                <Radio value="send">Send Link</Radio>
              </div>
              <div className="col-md-4">
                <Radio value="notAvailable">Not&nbsp;Available</Radio>
              </div>
              <div className="col-md-7">
                <Radio value="cancelled">Cancelled Appointments</Radio>
              </div>
            </div>
          </Radio.Group>

          <Divider orientation="left">Send to Patient in with in days</Divider>
          <Radio.Group
            onChange={(e) => setSelectedPatientDetail(e.target.value)}
          >
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <Radio value="oneWeek">1 Week</Radio>
              </div>
              <div className="col-md-4 col-sm-6">
                <Radio value="twoWeek">2 Week</Radio>
              </div>
              <div className="col-md-4 col-sm-6">
                <Radio value="oneMonth">1 Month</Radio>
              </div>
              <div className="col-md-4 col-sm-6">
                <Radio value="twoMonth">2 Month</Radio>
              </div>
              <div className="col-md-4 col-sm-6">
                <Radio value="sixMonth">6 Month</Radio>
              </div>
              <div className="col-md-4 col-sm-6">
                <Radio value="oneYear">1 Year</Radio>
              </div>
              <div className="col-md-8 col-sm-6">
                <Radio value="custome">Custome Patient</Radio>
              </div>
              <div className="col-md-4 col-sm-6">
                <Radio value="todayAppointment">Appointments</Radio>
              </div>
            </div>
          </Radio.Group>
          <Divider />

          {selectedPatientDetail === "custome" && (
            <Select
              mode="multiple"
              placeholder="Select Patients"
              className="w-100"
              loading={loader}
              onChange={(value) => setSelectedPatient(value)}
              onSearch={(value) => setSearchPatient(value)}
              onClick={() => fetchPatient("")}
              options={ptientList.map((value) => ({
                label:
                  value.patient_first_name +
                  " " +
                  value.patient_last_name +
                  " - " +
                  value.patient_phone_no +
                  " (" +
                  value.pid +
                  ")",
                value:
                  value.patient_first_name +
                  " " +
                  value.patient_last_name +
                  " - " +
                  value.patient_phone_no +
                  " (" +
                  value.pid +
                  ")",
              }))}
            />
          )}
        </div>
        <div className="col-md-6">
          {detailUI(template, smsData)}
          <Divider orientation="left">SMS Template</Divider>
          <TextArea
            showCount
            readOnly
            rows={4}
            value={smsData?.find((value) => value.type === template)?.message}
            className="formInput"
            style={{
              background: "#eee",
              boxShadow: "unset",
              borderColor: "#000",
            }}
          />
        </div>
        <div className="col-12">
          <Divider />
          <div className="buttonGroups mt-3">
            <Button className="Cancelbtn" onClick={handleCancel}>Cancel</Button>
            <Button className="Clickbtn" onClick={handleSubmitForm}>
              Send
            </Button>
          </div>
        </div>
      </div>
    </Spin>
  );
}
